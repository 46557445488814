@use "setting" as *;

/* ======================================================================
 utility
====================================================================== */

/* =================================
 layout
================================= */
// clearfix
.u_cf {
  @include cf;
}

// pc・sp 出し分け（media-queryはサイトの仕様に合わせて変更すること）
@include pc_tab {
  .u_pc {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
  .u_sp {
    display: none;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none;
    }
  }
}

@include sp {
  .u_pc {
    display: none;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none;
    }
  }
  .u_sp {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
}

/* =================================
 content_size
================================= */
.u_content_size {
  padding: 0 $sp_side_padding;

  @include pc_tab {
    margin: 0 auto;
    padding: 0;
    width: $content_width;
  }
}

/* =================================
 text
================================= */
.u_txt_10-1 {
  @include fz(10, 22, .3);
}

.u_txt_12-1 {
  @include fz(12, 18);
}

.u_txt_13-1 {
  @include fz(13, 22);
}
.u_txt_13-2 {
  @include pc_tab {
    @include fz(13, 22);
  }
  @include sp {
    @include fz(12, 20);
  }
}

.u_txt_14-1 {
  @include pc_tab {
    @include fz(14, 21);
  }
  @include sp {
    @include fz(12, 18);
  }
}
.u_txt_14-2 {
  @include fz(14, 21);
}
.u_txt_14-3 {
  @include pc_tab {
    @include fz(14, 25, 1.12);
  }
  @include sp {
    @include fz(14, 25);
  }
}
.u_txt_14-4 {
  @include fz(14, 38);
}
.u_txt_14-5 {
  @include fz(14, 22);
}
.u_txt_14-6 {
  @include fz(14, 22);
  @include sp {
    @include fz(11, 16);
  }
}
.u_txt_14-6 {
  @include fz(14, 30);
  @include sp {
    @include fz(12, 30);
  }
}
.u_txt_14-7 {
  @include fz(14, 22);
  @include sp {
    @include fz(11, 16);
  }
}
.u_txt_14-8 {
  @include fz(14, 22);
  @include sp {
    @include fz(13, 16);
  }
}

.u_txt_16-1 {
  @include pc_tab {
    @include fz(16, 24);
  }
  @include sp {
    @include fz(14, 21);
  }
}
.u_txt_16-2 {
  @include fz(16, 28);
}
.u_txt_16-3 {
  @include pc_tab {
    @include fz(16, 25);
  }
  @include sp {
    @include fz(16, 28);
  }
}
.u_txt_16-4 {
  @include pc_tab {
    @include fz(16, 28);
  }
  @include sp {
    @include fz(15, 26);
  }
}
.u_txt_16-5 {
  @include pc_tab {
    @include fz(16, 28);
  }
  @include sp {
    @include fz(14, 26);
  }
}
.u_txt_16-6 {
  @include pc_tab {
    @include fz(16, 26);
  }
  @include sp {
    @include fz(16, 26);
  }
}
.u_txt_16-7 {
  @include pc_tab {
    @include fz(16, 26);
  }
  @include sp {
    @include fz(15, 24);
  }
}
.u_txt_16-8 {
  @include pc_tab {
    @include fz(16, 26);
  }
  @include sp {
    @include fz(14, 26);
  }
}
.u_txt_16-9 {
  @include pc_tab {
    @include fz(16, 26);
  }
  @include sp {
    @include fz(15, 24);
  }
}
.u_txt_16-10 {
  @include pc_tab {
    @include fz(16, 26);
  }
  @include sp {
    @include fz(14, 24);
  }
}
.u_txt_16-11 {
  @include pc_tab {
    @include fz(16, 23);
  }
  @include sp {
    @include fz(16, 23);
  }
}
.u_txt_16-12 {
  @include pc_tab {
    @include fz(16, 23);
  }
  @include sp {
    @include fz(14, 23);
  }
}
.u_txt_16-13 {
  @include pc_tab {
    @include fz(16, 26);
  }
  @include sp {
    @include fz(14, 28);
  }
}
.u_txt_16-14 {
  @include pc_tab {
    @include fz(16, 26);
  }
  @include sp {
    @include fz(14, 22);
  }
}
.u_txt_16-15 {
  @include pc_tab {
    @include fz(16, 26);
  }
  @include sp {
    @include fz(14, 22);
  }
}
.u_txt_16-16 {
  @include pc_tab {
    @include fz(16, 22);
  }
  @include sp {
    @include fz(16, 22);
  }
}

.u_txt_17-1 {
  @include pc_tab {
    @include fz(17, 22);
  }
  @include sp {
    @include fz(14, 25);
  }
}

.u_txt_18-1 {
  @include pc_tab {
    @include fz(18, 27);
  }
  @include sp {
    @include fz(18, 30);
  }
}
.u_txt_18-2 {
  @include pc_tab {
    @include fz(18, 27);
  }
  @include sp {
    @include fz(16, 28);
  }
}
.u_txt_18-3 {
  @include pc_tab {
    @include fz(18, 30, 1.08);
  }
  @include sp {
    @include fz(17, 28, 1.02);
  }
}
.u_txt_18-4 {
  @include pc_tab {
    @include fz(18, 36);
  }
  @include sp {
    @include fz(16, 36);
  }
}
.u_txt_18-4 {
  @include pc_tab {
    @include fz(18, 31);
  }
  @include sp {
    @include fz(16, 28);
  }
}
.u_txt_18-5 {
  @include pc_tab {
    @include fz(18, 30);
  }
  @include sp {
    @include fz(16, 28);
  }
}
.u_txt_18-7 {
  @include pc_tab {
    @include fz(18, 28);
  }
  @include sp {
    @include fz(18, 28);
  }
}
.u_txt_18-8 {
  @include pc_tab {
    @include fz(18, 28);
  }
  @include sp {
    @include fz(22, 28);
  }
}
.u_txt_18-9 {
  @include pc_tab {
    @include fz(18, 30);
  }
  @include sp {
    @include fz(16, 24);
  }
}
.u_txt_18-10 {
  @include pc_tab {
    @include fz(18, 30);
  }
  @include sp {
    @include fz(15, 28);
  }
}
.u_txt_18-11 {
  @include pc_tab {
    @include fz(18, 30);
  }
  @include sp {
    @include fz(16, 30);
  }
}
.u_txt_18-12 {
  @include pc_tab {
    @include fz(18, 30);
  }
  @include sp {
    @include fz(18, 30);
  }
}
.u_txt_18-13 {
  @include pc_tab {
    @include fz(18, 30);
  }
  @include sp {
    @include fz(14, 22);
  }
}

.u_txt_20-1 {
  @include pc_tab {
    @include fz(20, 34);
  }
  @include sp {
    @include fz(16, 24);
  }
}
.u_txt_20-2 {
  @include pc_tab {
    @include fz(20, 34);
  }
  @include sp {
    @include fz(18, 30);
  }
}
.u_txt_20-3 {
  @include pc_tab {
    @include fz(20, 34, 1.2);
  }
  @include sp {
    @include fz(18, 27, 1.08);
  }
}
.u_txt_20-4 {
  @include fz(20, 28);
}
.u_txt_20-5 {
  @include fz(20, 28);
  @include sp {
    @include fz(15, 20);
  }
}
.u_txt_20-6 {
  @include fz(20, 30);
  @include sp {
    @include fz(20, 30);
  }
}

.u_txt_21-1 {
  @include pc_tab {
    @include fz(21, 36);
  }
  @include sp {
    @include fz(14, 24);
  }
}
.u_txt_22-1 {
  @include pc_tab {
    @include fz(22, 40);
  }
  @include sp {
    @include fz(18, 30);
  }
}

.u_txt_22-2 {
  @include pc_tab {
    @include fz(22, 40);
  }
  @include sp {
    @include fz(20, 30);
  }
}
.u_txt_22-3 {
  @include pc_tab {
    @include fz(22, 34);
  }
  @include sp {
    @include fz(18, 30);
  }
}

.u_txt_22-4 {
  @include pc_tab {
    @include fz(22, 40);
  }
  @include sp {
    @include fz(20, 30);
  }
}
.u_txt_22-5 {
  @include pc_tab {
    @include fz(22, 40);
  }
  @include sp {
    @include fz(18, 40);
  }
}
.u_txt_22-6 {
  @include pc_tab {
    @include fz(22, 40);
  }
  @include sp {
    @include fz(14, 30);
  }
}
.u_txt_22-7 {
  @include pc_tab {
    @include fz(22, 30);
  }
  @include sp {
    @include fz(14, 30);
  }
}

.u_txt_22-8 {
  @include pc_tab {
    @include fz(22, 34);
  }
  @include sp {
    @include fz(20, 30);
  }
}
.u_txt_22-9 {
  @include pc_tab {
    @include fz(22, 34);
  }
  @include sp {
    @include fz(18, 34);
  }
}
.u_txt_22-10 {
  @include pc_tab {
    @include fz(22, 30);
  }
  @include sp {
    @include fz(18, 30);
  }
}
.u_txt_22-11 {
  @include pc_tab {
    @include fz(22, 30);
  }
  @include sp {
    @include fz(14, 22);
  }
}
.u_txt_22-12 {
  @include pc_tab {
    @include fz(22, 40);
  }
  @include sp {
    @include fz(14, 22);
  }
}
.u_txt_22-13 {
  @include pc_tab {
    @include fz(22, 34);
  }
  @include sp {
    @include fz(22, 34);
  }
}
.u_txt_22-14 {
  @include pc_tab {
    @include fz(22, 34);
  }
  @include sp {
    @include fz(18, 30);
  }
}

.u_txt_23-1 {
  @include pc_tab {
    @include fz(23, 34);
  }
  @include sp {
    @include fz(20, 30);
  }
}

.u_txt_26-1 {
  @include pc_tab {
    @include fz(26, 40);
  }
  @include sp {
    @include fz(22, 36);
  }
}
.u_txt_26-2 {
  @include pc_tab {
    @include fz(26, 40);
  }
  @include sp {
    @include fz(20, 40);
  }
}
.u_txt_26-3 {
  @include pc_tab {
    @include fz(26, 36);
  }
  @include sp {
    @include fz(20, 32);
  }
}
.u_txt_26-4 {
  @include pc_tab {
    @include fz(26, 40);
  }
  @include sp {
    @include fz(22, 40);
  }
}

.u_txt_28-1 {
  @include pc_tab {
    @include fz(28, 48);
  }
  @include sp {
    @include fz(24, 48);
  }
}
.u_txt_28-2 {
  @include pc_tab {
    @include fz(28, 47);
  }
  @include sp {
    @include fz(24, 35);
  }
}
.u_txt_28-3 {
  @include pc_tab {
    @include fz(28, 23);
  }
  @include sp {
    @include fz(22, 23);
  }
}
.u_txt_28-4 {
  @include pc_tab {
    @include fz(28, 40);
  }
  @include sp {
    @include fz(26, 30);
  }
}

.u_txt_30-1 {
  @include pc_tab {
    @include fz(30, 50);
  }
  @include sp {
    @include fz(26, 40);
  }
}
.u_txt_30-2 {
  @include pc_tab {
    @include fz(30, 54);
  }
  @include sp {
    @include fz(24, 38);
  }
}
.u_txt_30-3 {
  @include pc_tab {
    @include fz(30, 54);
  }
  @include sp {
    @include fz(36, 60);
  }
}
.u_txt_30-4 {
  @include pc_tab {
    @include fz(30, 54);
  }
  @include sp {
    @include fz(22, 36);
  }
}
.u_txt_30-5 {
  @include pc_tab {
    @include fz(30, 56);
  }
  @include sp {
    @include fz(24, 36);
  }
}
.u_txt_30-6 {
  @include pc_tab {
    @include fz(30, 36);
  }
  @include sp {
    @include fz(30, 36);
  }
}
.u_txt_30-7 {
  @include pc_tab {
    @include fz(30, 40);
  }
  @include sp {
    @include fz(30, 40);
  }
}
.u_txt_30-8 {
  @include pc_tab {
    @include fz(30, 40);
  }
  @include sp {
    @include fz(26, 36);
  }
}

.u_txt_32-1 {
  @include pc_tab {
    @include fz(32, 40);
  }
  @include sp {
    @include fz(20, 30);
  }
}

.u_txt_33-1 {
  @include fz(33, 47, 2.64);
}

.u_txt_40-1 {
  @include pc_tab {
    @include fz(40, 54);
  }
  @include sp {
    @include fz(28, 42);
  }
}
.u_txt_40-2 {
  @include pc_tab {
    @include fz(40, 40);
  }
  @include sp {
    @include fz(26, 30);
  }
}
.u_txt_40-3 {
  @include pc_tab {
    @include fz(40, 56);
  }
  @include sp {
    @include fz(30, 40);
  }
}
.u_txt_46-1 {
  @include pc_tab {
    @include fz(46, 70);
  }
  @include sp {
    @include fz(30, 30);
  }
}
.u_txt_51-1 {
  @include pc_tab {
    @include fz(51, 70);
  }
  @include sp {
    @include fz(34, 45);
  }
}

/* =================================
 text weight
================================= */
.u_lighter {
  font-weight: lighter;
}

.u_normal {
  font-weight: normal;
}
.u_bold {
  font-weight: bold;
}
.u_weight_700 {
  font-weight: 700;
}

/* =================================
 font family
================================= */
.u_ff_1 {
  font-family: $font_2; // Roboto
}

.u_ff_2 {
  font-family: $font_3; // 遊ゴシック
}

.u_ff_4 {
  font-family: $font_4; // Zen Maru Gothic
}
.u_ff_5 {
  font-family: $font_5;
}

/* =================================
 font color
================================= */
.u_fc_white {
  color: $white;
}

.u_fc_black {
  color: $black;
}

.u_fc_1 {
  color: $color_1;
}

.u_fc_8 {
  color: $color_8;
}

.u_fc_10 {
  color: $color_10;
}

.u_fc_12 {
  color: $color_12;
}

.u_fc_13 {
  color: $color_13;
}

.u_fc_14 {
  color: $color_14;
}

.u_fc_15 {
  color: $color_15;
}

.u_fc_23 {
  color: $color_23;
}

.u_fc_30 {
  color: $color_30;
}

/* =================================
 text align
================================= */
.u_ta_c {
  text-align: center;
}

.u_ta_r {
  text-align: right;
}
.u_ta_l {
  text-align: left;
}

.u_ta_cl {
  @include sp {
    text-align: center;
  }
}

.u_ta_lc {
  @include pc_tab {
    text-align: center;
  }
  @include sp {
    text-align: left;
  }
}

.u_ta_cr {
  @include sp {
    text-align: center;
  }
  @include pc_tab {
    text-align: right;
  }
}

.u_ta_rc {
  @include sp {
    text-align: right;
  }
  @include pc_tab {
    text-align: center;
  }
}

/* =================================
 paragraph margin
================================= */
.u_paragraph {
  & + & {
    margin: 15px 0 0;
  }
}

/* =================================
 hover animation
================================= */
.u_hover_1 {
  transition: opacity .3s;

  @include pc {
    &:hover {
      opacity: .7;
    }
  }
}

/* =================================
 letter-spacing
================================= */
.u_letter_spacing_1 {
  letter-spacing: .66px;
}
.u_letter_spacing_2 {
  letter-spacing: .6px;
}
