@use "setting" as *;

/* ======================================================================
 component
====================================================================== */

/* =================================
 title
================================= */

// ページタイトル
.c_ttl_1 {
  position: relative;
  margin: 70px 0 0 65px;

  @include sp {
    margin: 70px auto 0;
  }
  &::before {
    position: absolute;
    top: 5px;
    content: "";

    @include sp {
      top: -60px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  &::after {
    position: absolute;
    bottom: -30px;
    left: -55px;
    width: 110px;
    height: 8px;
    background: url(#{$img_dir}/common/page-ttl-dot.png)no-repeat;
    content: "";

    @include sp {
      left: 40%;
      background: url(#{$img_dir}/common/sp/page-ttl-dot.png)no-repeat;
    }
  }
  &._about {
    &::before {
      left: -82px;
      width: 72px;
      height: 56px;
      background: url(#{$img_dir}/p-about/page-ttl-icon.svg)no-repeat;

      @include sp {
        left: 50%;
        width: 76px;
        height: 55px;
        background: url(#{$img_dir}/p-about/sp/page-ttl-icon.svg)no-repeat;
      }
    }
  }
  &._faq {
    &::before {
      left: -82px;
      width: 72px;
      height: 56px;
      background: url(#{$img_dir}/p-faq/page-ttl-icon.svg)no-repeat;

      @include sp {
        left: 50%;
        width: 76px;
        height: 55px;
        background: url(#{$img_dir}/p-faq/sp/page-ttl-icon.svg)no-repeat;
      }
    }
  }
  &._access {
    &::before {
      left: -65px;
      width: 54px;
      height: 55px;
      background: url(#{$img_dir}/p-access/page-ttl-icon.svg)no-repeat;

      @include sp {
        left: 50%;
        background: url(#{$img_dir}/p-access/sp/page-ttl-icon.svg)no-repeat;
      }
    }
  }
  &._archive-column {
    &::before {
      left: -85px;
      width: 73px;
      height: 56px;
      background: url(#{$img_dir}/archive-column/page-ttl-icon.svg)no-repeat;

      @include sp {
        left: 50%;
        width: 73px;
        height: 55px;
        background: url(#{$img_dir}/archive-column/sp/page-ttl-icon.svg)no-repeat;
      }
    }
  }
  &._archive-news {
    &::before {
      left: -75px;
      width: 62px;
      height: 56px;
      background: url(#{$img_dir}/archive-news/page-ttl-icon.svg)no-repeat;

      @include sp {
        left: 50%;
        width: 61px;
        height: 56px;
        background: url(#{$img_dir}/archive-news/sp/page-ttl-icon.svg)no-repeat;
      }
    }
  }
  &._recruit {
    &::before {
      left: -75px;
      width: 62px;
      height: 56px;
      background: url(#{$img_dir}/p-recruit/page-ttl-icon.svg)no-repeat;

      @include sp {
        left: 50%;
        width: 61px;
        height: 56px;
        background: url(#{$img_dir}/p-recruit/sp/page-ttl-icon.svg)no-repeat;
      }
    }
  }
  &._colo {
    &::before {
      left: -85px;
      width: 78px;
      height: 55px;
      background: url(#{$img_dir}/p-colorectal/page-ttl-icon.svg)no-repeat;

      @include sp {
        left: 50%;
        width: 78px;
        height: 55px;
        background: url(#{$img_dir}/p-colorectal/sp/page-ttl-icon.svg)no-repeat;
      }
    }
  }
  &._cancer {
    &::before {
      left: -65px;
      width: 54px;
      height: 58px;
      background: url(#{$img_dir}/p-cancer/page-ttl-icon.svg)no-repeat;

      @include sp {
        left: 50%;
        width: 54px;
        height: 58px;
        background: url(#{$img_dir}/p-cancer/sp/page-ttl-icon.svg)no-repeat;
      }
    }
  }
  &._surgery {
    &::before {
      left: -65px;
      width: 54px;
      height: 58px;
      background: url(#{$img_dir}/p-surgery/page-ttl-icon.svg)no-repeat;

      @include sp {
        left: 50%;
        width: 54px;
        height: 58px;
        background: url(#{$img_dir}/p-surgery/sp/page-ttl-icon.svg)no-repeat;
      }
    }
  }
  &._check {
    &::before {
      left: -65px;
      width: 65px;
      height: 58px;
      background: url(#{$img_dir}/p-check-up/page-ttl-icon.svg)no-repeat;

      @include sp {
        left: 50%;
        width: 65px;
        height: 58px;
        background: url(#{$img_dir}/p-check-up/sp/page-ttl-icon.svg)no-repeat;
      }
    }
  }
  &._private {
    &::before {
      top: -2px;
      left: -75px;
      width: 68px;
      height: 59px;
      background: url(#{$img_dir}/p-private/page-ttl-icon.svg)no-repeat;

      @include sp {
        top: -65px;
        left: 50%;
        width: 68px;
        height: 59px;
        background: url(#{$img_dir}/p-private/sp/page-ttl-icon.svg)no-repeat;
      }
    }
  }
  &._breast {
    &::before {
      top: -2px;
      left: -65px;
      width: 54px;
      height: 58px;
      background: url(#{$img_dir}/p-breast/page-ttl-icon.svg)no-repeat;

      @include sp {
        top: -65px;
        left: 50%;
        width: 54px;
        height: 58px;
        background: url(#{$img_dir}/p-breast/sp/page-ttl-icon.svg)no-repeat;
      }
    }
  }
  .c_ttl_1_en {
    margin: 0 0 0 5px;
    text-transform: capitalize;

    @include sp {
      margin: 0;
    }
  }
}

.c_sec_ttl_01 {
  width: fit-content;
  font-weight: bold;
  font-size: 26px;
  font-family: $font_4;

  @include sp {
    font-size: 20px;
  }
}

.c_sec_ttl_02 {
  .c_sec_ttl_jp {
    position: relative;
    width: fit-content;
    color: $color_10;
    font-weight: bold;
    font-family: $font_4;

    &::before {
      position: absolute;
      top: 10px;
      left: -18px;
      width: 79px;
      height: 69px;
      background-repeat: no-repeat;
      content: "";
      transform: translate(-100%, 0);

      @include sp {
        top: -60px;
        left: 36px;
        transform: translate(-50%, 0);
      }
    }
  }
  .c_sec_ttl_en {
    position: relative;
    color: $color_14;
    text-transform: capitalize;
    font-weight: bold;
    font-family: $font_4;
    &::before {
      position: absolute;
      top: 50px;
      left: 0;
      width: 55px;
      height: 8px;
      background-image: url(#{$img_dir}/p-top/sec-ttl-deco.png);
      content: "";
      transform: translate(0, -50%);

      @include sp {
        top: 40px;
        width: 50px;
        height: 7px;
        background-image: url(#{$img_dir}/p-top/sp/sec-ttl-deco.png);
        background-size: cover;
      }
    }
    &._underlayer {
      &::before {
        @include sp {
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}


.c_sec_ttl_03 {
  width: fit-content;
  color: $color_10;
  font-weight: bold;
  font-size: 26px;
  font-family: $font_4;
  line-height: calc(36 / 26);

  @include sp {
    font-size: 22px;
    line-height: calc(30 / 26);
  }
}

.c_sec_ttl_04 {
  position: relative;
  margin: 0 auto;
  width: fit-content;
  &::before {
    position: absolute;
    width: 79px;
    height: 69px;
    background-repeat: no-repeat;
    content: "";
    -webkit-transform: translate(-100%, 0);
    transform: translate(-50%, -100%);

    @include sp {
      top: 5px;
      left: 60%;
    }
  }
  &::after {
    position: absolute;
    bottom: -35px;
    left: 50%;
    width: 55px;
    height: 8px;
    background-image: url(#{$img_dir}/p-top/sec-ttl-deco.png);
    content: "";
    transform: translate(-50%, -50%);

    @include sp {
      bottom: -25px;
      width: 50px;
      height: 7px;
      background-image: url(#{$img_dir}/p-top/sp/sec-ttl-deco.png);
      background-size: cover;
    }
  }
  &.sec_ttl_route {
    &::before {
      top: -60px;
      left: 50%;
      width: 68px;
      height: 64px;
      background: url(#{$img_dir}/p-access/sec_ttl_route.svg) no-repeat;
      transform: translate(-50%, 0);

      @include sp {
        width: 69px;
        height: 65px;
        background: url(#{$img_dir}/p-access/sp/sec_ttl_route.svg) no-repeat;
      }
    }
  }
  .c_sec_ttl_jp {
    position: relative;
    width: 100%;
    color: $color_10;
    text-align: center;
    font-weight: bold;
    font-family: $font_4;
  }
  .c_sec_ttl_en {
    position: relative;
    margin: 0 auto;
    width: fit-content;
    color: $color_14;
    text-transform: capitalize;
    font-weight: bold;
    font-family: $font_4;
  }
}
.c_sec_ttl_05 {
  display: flex;
  align-items: center;
  width: fit-content;

  @include sp {
    flex-direction: column;
  }
  .c_sec_ttl_jp {
    position: relative;
    width: fit-content;
    color: $color_10;
    font-weight: bold;
    font-family: $font_4;

    &::before {
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
      content: "";

      @include sp {
        top: -60px;
        left: 36px;
        transform: translate(-50%, 0);
      }
    }
    &::after {
      position: absolute;
      top: calc(100% + 25px);
      left: 0;
      width: 55px;
      height: 8px;
      background-image: url(#{$img_dir}/p-top/sec-ttl-deco.png);
      content: "";

      @include sp {
        top: calc(100% + 35px);
        left: 50%;
        width: 50px;
        height: 7px;
        background-image: url(#{$img_dir}/p-top/sp/sec-ttl-deco.png);
        background-size: cover;
        transform: translateX(-50%);
      }
    }
  }
  .c_sec_ttl_en {
    position: relative;
    margin: 0 0 0 20px;
    color: $color_14;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 22px;
    font-family: $font_4;

    @include sp {
      margin: 5px auto 0;
      font-size: 14px;
    }
  }
}
.c_sec_lead_1 {
  margin: 55px auto 0;

  @include sp {
    margin: 35px auto 0;
  }
}

.c_ttl_2 {
  position: relative;
  display: inline-block;
  margin: 0 0 0 5px;
  padding: 0 0 0 20px;

  @include sp {
    padding: 0 0 0 16px;
  }
  &._2 {
    @include sp {
      padding: 0 0 0 5px;
    }
    &::before {
      @include sp {
        left: -3px;
        height: 87px;
      }
    }
  }
  &._3 {
    @include sp {
      padding: 0 0 0 5px;
    }
    &::before {
      @include sp {
        left: -3px;
        height: 50px;
      }
    }
  }
  &._4 {
    @include sp {
      padding: 0 0 0 5px;
    }
    &::before {
      @include sp {
        left: -3px;
        height: 55px;
      }
    }
  }
  &._5 {
    @include sp {
      padding: 0 0 0 15px;
    }
    &::before {
      @include sp {
        left: -3px;
        height: 52px;
      }
    }
  }
  &._6 {
    @include sp {
      padding: 0 0 0 10px;
      &::before {
        height: 100%;
      }
    }
  }
  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 5px;
    height: 30px;
    border-radius: 6px;
    background: $color_19;
    content: "";
    transform: translate(-100%, -50%);

    @include sp {
      height: 23px;
    }
  }
  &._7 {
    @include sp {
      &:before {
        height: 52px;
      }
    }
  }
}

.c_ttl_3 {
  position: relative;
  padding: 0 0 15px;
  border-bottom: 6px dotted $color_32;

  @include sp {
    padding: 0 0 10px;
  }
}

/* =================================
 buttons
================================= */
.c_btn_1 {
  position: relative;
  top: 0;
  left: 0;
  max-width: 336px;
  height: 60px;
  transition: all .3s;
  &::before {
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background: $color_6;
    content: "";

    @include sp {
    }
  }

  &.c_btn_1_mini {
    height: 44px;

    @include sp {
      height: 50px;
    }
  }
  &.c_btn_1_mini_2 {
    width: 250px;
    height: 60px;

    @include sp {
      height: 50px;
    }
    &::before {
      background: $color_7;
    }
  }
  &.c_btn_1_mini_3 {
    width: 250px;
    height: 60px;

    @include sp {
      width: calc(50% - 3px);
      height: 45px;
    }
    &::before {
      background: $color_7;
    }
  }
  &.c_btn_1_mini_4 {
    @include sp {
      width: 280px;
      height: 50px;
    }
    &::before {
      background: $color_9;
    }
  }
  &.c_btn_1_mini_5 {
    width: 250px;
    height: 60px;

    @include sp {
      width: 170px;
      height: 45px;
    }
    &::before {
      background: $color_7;

      @include sp {
        left: 3px;
      }
    }
  }

  .link_area {
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 1px solid $color_15;
    border-radius: 30px;
    background: $white;
    font-weight: bold;
    font-size: 16px;
    transition: all .3s;
    &::after {
      position: absolute;
      top: 50%;
      right: 23px;
      width: 6px;
      height: 10px;
      background-image: url(#{$img_dir}/p-top/c_btn_1-arrow.svg);
      background-size: cover;
      content: "";
      transition: all .3s;
      transform: translate(-100%, -40%);

      @include sp {
        right: 15px;
      }
    }
    &:hover {
      @include pc_tab {
        top: 3px;
        background-color: $color_16;
        transition: all .3s;
        &:after {
          right: 20px;
          transition: all .3s;
        }
      }
    }
    &._about {
      @include sp {
        font-size: 14px;
      }
      &:hover {
        @include pc_tab {
          &:after {
            right: 23px;
          }
        }
      }
      &:after {
        @include sp {
          right: 10px;
        }
      }
    }
    .c_btn_txt {
      color: $color_15;
    }
    &._scroll_btn {
      &::after {
        transform: translate(-100%, -40%)rotate(90deg);
      }
      &:hover {
        @include pc_tab {
          &:after {
            right: 23px;
          }
        }
      }
    }
  }
  &.c_btn_1_mini {
    width: fit-content;
    .link_area {
      &::after {
        right: 12px;
        transition: all .3s;
      }
      &:hover {
        top: 3px;
        &::after {
          right: 9px;
          transition: all .3s;
        }
      }
    }
  }
}

.c_btn_1_mini_2 + .c_btn_1_mini_2 {
  margin: 0 0 0 40px;

  @include sp {
    margin: 15px 0 0;
  }
}
.c_btn_1_mini_2:nth-of-type(5) {
  margin: 30px 0 0;

  @include sp {
    margin: 15px 0 0;
  }
}
.c_btn_1_mini_2:nth-of-type(n+6) {
  margin: 30px 0 0 40px;

  @include sp {
    margin: 15px 0 0;
  }
}

.c_btn_1_mini_3 + .c_btn_1_mini_3 {
  margin: 0 0 0 40px;

  @include sp {
    margin: 6px 0 0 2px;
  }
}

.c_btn_1_mini_3:nth-of-type(odd) {
  @include sp {
    margin: 10px 0 0;
  }
}
.c_btn_1_mini_3:nth-of-type(even) {
  @include sp {
    margin: 10px 0 0 5px;
  }
}
.c_btn_1_mini_3:nth-of-type(5) {
  margin: 30px 0 0;

  @include sp {
    margin: 10px 0 0;
  }
}
.c_btn_1_mini_3:nth-of-type(n+6) {
  margin: 30px 0 0 40px;

  @include sp {
    margin: 10px 0 0 5px;
  }
}
.c_btn_1_mini_3:nth-of-type(2) {
  @include sp {
    margin: 0 0 0 5px;
  }
}
.c_btn_1_mini_3:nth-of-type(7) {
  @include sp {
    margin: 10px 0 0;
  }
}
.c_btn_1_mini_3:nth-of-type(9) {
  margin: 30px 0 0;

  @include sp {
    margin: 10px 0 0;
  }
}
.c_btn_1_mini_3:first-of-type {
  @include sp {
    margin: 0;
  }
}

.c_btn_1_mini_5 + .c_btn_1_mini_5 {
  margin: 0 0 0 40px;

  @include sp {
    margin: 10px 0 0 5px;
  }
}
.c_btn_1_mini_5:nth-of-type(2) {
  @include sp {
    margin: 0 0 0 5px;
  }
}
.c_btn_1_mini_5:nth-of-type(3) {
  @include sp {
    margin: 10px 0 0;
  }
}




.c_btn_2_wrapper {
  position: relative;
  width: 380px;
  height: 70px;

  @include sp {
    width: 345px;
  }
  &::before {
    position: absolute;
    top: 3px;
    left: 0;
    width: 380px;
    height: 70px;
    border-radius: 35px;
    content: "";

    @include sp {
      width: 345px;
      height: 70px;
    }
  }
  &:hover {
    @include pc_tab {
      .c_btn_2 {
        top: 3px;
        left: 0;
        transition: all .3s;
      }
    }
  }
}
.c_btn_2_wrapper_mini {
  position: relative;
  width: 240px;
  height: 54px;

  @include sp {
    width: 345px;
  }
  &::before {
    position: absolute;
    top: 3px;
    left: 0;
    width: 240px;
    height: 54px;
    border-radius: 35px;
    content: "";

    @include sp {
    }
  }
  &:hover {
    @include pc_tab {
      .c_btn_2 {
        top: 3px;
        left: 0;
        transition: all .3s;
      }
    }
  }
}
.c_btn_2 {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 35px;
  transition: all .3s;

  @include sp {
    width: 345px;
    height: 70px;
  }
  &.btn_web_hum {
    @include sp {
      &::after {
        position: absolute;
        top: -5px;
        left: 30px;
        width: 68px;
        height: 68px;
        background-image: url(#{$img_dir}/common/hum-web.png);
        background-size: cover;
        content: "";
      }
    }
  }
}

/* =================================
 icon
================================= */
.c_pic_num {
  .img {
    @include sp {
      width: 50px;
      height: 51px;
    }
  }
}

/* =================================
 table
================================= */
.c_table_1 {
  width: 1020px;

  @include sp {
    width: 345px;
    border-radius: 4px;
  }
  .c_table_tr {
    display: flex;

    @include sp {
      flex-direction: column;
    }
    &._pt02 {
      @include sp {
        flex-direction: row;
      }
    }
  }

  .c_table_th {
    display: flex;
    align-items: center;
    padding: 0 0 0 22px;
    width: 223px;
    border-top: 1px solid $color_32;
    border-left: 1px solid $color_32;
    background: $color_9;

    @include sp {
      padding: 17px 0 15px 15px;
      width: 345px;
      border-right: 1px solid $color_32;
    }
    &._pt02 {
      @include sp {
        align-items: flex-start;
        padding: 15px 10px 0;
        width: 100px;
        border-right: none;
      }
    }
    &._pt03 {
      align-items: center;
      padding: 30px 20px;
      width: 223px;

      @include sp {
        align-items: flex-start;
        padding: 10px 15px;
        width: 100%;
        border-right: 1px solid $color_32;
      }
    }
    &._pt04 {
      align-items: center;
      padding: 20px;
      width: 223px;

      @include sp {
        align-items: flex-start;
        padding: 10px 15px;
        width: 100%;
        border-right: 1px solid $color_32;
      }
    }
  }
  .c_table_tr:first-of-type {
    .c_table_th {
      border-radius: 6px 0 0 0;

      @include sp {
        border-radius: 4px 4px 0 0;
      }
    }
  }
  .c_table_tr:last-of-type {
    .c_table_th {
      border-bottom: 1px solid $color_32;
      border-radius: 0 0 0 6px;

      @include sp {
        border-bottom: none;
        border-radius: 0;
      }
      &.c_table_th_single {
        border-radius: 6px 0 0 6px;

        @include sp {
          border-radius: 4px 4px 0 0;
        }
      }
    }
  }
  .c_table_tr._pt02:first-of-type {
    .c_table_th {
      border-radius: 6px 0 0 0;

      @include sp {
        border-radius: 4px 0 0 0;
      }
    }
  }
  .c_table_tr._pt02:last-of-type {
    .c_table_th {
      border-bottom: 1px solid $color_32;
      border-radius: 0 0 0 6px;

      @include sp {
        border-radius: 0 0 0 4px;
      }
    }
  }
  .c_table_td {
    padding: 25px 20px 26px;
    width: calc(1020px - 223px);
    border-top: 1px solid $color_32;
    border-right: 1px solid $color_32;
    background: $white;

    @include sp {
      padding: 12px 15px;
      width: 345px;
      border-left: 1px solid $color_32;
    }
    &._pt02 {
      @include sp {
        padding: 12px 10px;
        width: 205px;
      }
    }
    &._pt03 {
      align-items: center;
      padding: 30px 20px;
      width: calc( 100% - 223px);

      @include sp {
        align-items: flex-start;
        padding: 10px 15px;
        width: 100%;
        border-right: 1px solid $color_32;
      }
    }
    &._pt04 {
      align-items: center;
      padding: 20px;
      width: calc( 100% - 223px);

      @include sp {
        align-items: flex-start;
        padding: 10px 15px;
        width: 100%;
        border-right: 1px solid $color_32;
      }
    }
    &._pt05 {
      display: flex;
      align-items: center;
      padding: 20px;
      width: calc( 100% - 223px);

      @include sp {
        align-items: flex-start;
        padding: 10px 15px;
        width: 100%;
        border-right: 1px solid $color_32;
      }
    }
  }
  .c_table_tr:first-of-type {
    .c_table_td {
      border-radius: 0 6px 0 0;

      @include sp {
        border-radius: 0;
      }
    }
  }
  .c_table_tr:last-of-type {
    .c_table_td {
      border-bottom: 1px solid $color_32;
      border-radius: 0 0 6px 0;

      @include sp {
        border-radius: 0 0 4px 4px;
      }
      &.c_table_td_single {
        border-radius: 0 6px 6px 0;

        @include sp {
          border-radius: 0 0 4px 4px;
        }
      }
    }
  }
  .c_table_tr .c_table_td._pt06 {
    @include sp {
      padding: 12px 10px;
      width: 205px;
      border-radius: 0 0 4px 0;
    }
  }
}
.c_table_2 {
  overflow-x: auto;
  width: 1020px;
  border-collapse: separate;

  @include sp {
    width: 345px;
    border-radius: 4px;
  }
  &._2 {
    @include sp {
      width: 100%;
    }
  }
  .c_table_tr {
    display: flex;
  }
  .c_table_tr_2 {
    .c_table_th:first-of-type {
      width: 320px;

      @include sp {
        width: 100px;
      }
    }
    .c_table_th:not(:first-of-type) {
      width: initial;
    }
  }

  .c_table_th {
    display: table-cell;
    align-items: center;
    padding: 18px 22px;
    width: 340px;
    border-top: 1px solid $color_32;
    border-left: 1px solid $color_32;
    background: $color_9;

    @include sp {
      padding: 12px 0 10px 15px;
      width: 195px;
    }
    &._pt02 {
      @include sp {
        width: 220px;
      }
    }
    &._pt03 {
      padding: 19px 22px;
      width: 450px;

      @include sp {
        width: calc(100% / 3);
      }
      &:first-of-type {
        width: 120px;

        @include sp {
          width: calc(100% / 3);
        }
      }
    }
    &._pt04 {
      padding: 19px 12px;
      width: 450px;

      @include sp {
        width: calc(100% / 3);
      }
      &:first-of-type {
        width: 115px;

        @include sp {
          width: 16.8115%;
        }
      }
      &:nth-of-type(2) {
        width: 660px;

        @include sp {
          width: 56.5217%;
        }
      }
      &:last-of-type {
        width: 245px;

        @include sp {
          width: 26.6668%;
        }
      }
    }
    &._pt05 {
      padding: 19px 9px;
      width: 450px;

      @include sp {
        width: calc(100% / 3);
      }
      &:first-of-type {
        width: 115px;

        @include sp {
          width: 24.0579%;
        }
      }
      &:nth-of-type(2) {
        width: 660px;

        @include sp {
          width: 50.4347%;
        }
      }
      &:last-of-type {
        width: 245px;

        @include sp {
          width: 25.5074%;
        }
      }
    }
    &._pt06 {
      @include sp {
        padding: 25px 0 23px;
      }
    }
    &._pt07 {
      display: table-cell;
      padding: 155px 112px;
      width: initial;

      @include sp {
        padding: 15px 8px;
      }
    }
  }
  .c_table_th:first-of-type {
    border-radius: 6px 0 0 0;

    @include sp {
      border-radius: 4px 0 0 0;
    }
  }
  .c_table_th:last-of-type {
    border-right: 1px solid $color_32;
    border-radius: 0 6px 0 0;

    @include sp {
      border-radius: 0 4px 0 0;
    }
  }
  .c_table_td {
    display: flex;
    align-items: center;
    padding: 27px 21px 20px 21px;
    width: 340px;
    border-top: 1px solid $color_32;
    border-left: 1px solid $color_32;
    background: $white;

    @include sp {
      padding: 7px 15px 5px;
      width: 195px;
      border-left: 1px solid $color_32;
    }
    &._pt02 {
      @include sp {
        width: 220px;
      }
    }
    &._pt03 {
      padding: 19px 21px;
      width: 450px;

      @include sp {
        width: calc(100% / 3);
      }
      &:first-of-type {
        width: 120px;

        @include sp {
          width: calc(100% / 3);
        }
      }
    }
    &._pt04 {
      padding: 19px 20px;
      width: 450px;

      @include sp {
        padding: 19px 12px;
        width: calc(100% / 3);
      }
      &:first-of-type {
        width: 115px;

        @include sp {
          width: 16.8115%;
        }
      }
      &:nth-of-type(2) {
        width: 660px;

        @include sp {
          width: 56.5217%;
        }
      }
      &:last-of-type {
        width: 245px;

        @include sp {
          width: 26.6668%;
        }
      }
    }
    &._pt05 {
      padding: 19px 20px;
      width: 450px;

      @include sp {
        padding: 19px 9px;
        width: calc(100% / 3);
      }
      &:first-of-type {
        width: 115px;

        @include sp {
          width: 24.0579%;
        }
      }
      &:nth-of-type(2) {
        width: 660px;

        @include sp {
          width: 50.4347%;
        }
      }
      &:last-of-type {
        width: 245px;

        @include sp {
          width: 25.5074%;
        }
      }
    }
    &._pt06 {
      display: table-cell;
      padding: 20px;
      width: initial;

      @include sp {
        padding: 13px 0 20px 10px;
      }
    }
  }
  .c_table_td:last-of-type {
    border-right: 1px solid $color_32;
  }
  .c_table_tr:last-of-type,
  .c_table_tr_2:last-of-type {
    .c_table_td {
      border-bottom: 1px solid $color_32;
    }

    .c_table_td:last-of-type {
      border-radius: 0 0 6px 0;

      @include sp {
        border-radius: 0 0 4px 0;
      }
    }
    .c_table_td._pt06:last-of-type {
      border-radius: 0 0 6px 0;

      @include sp {
        border-radius: 0 0 4px 4px;
      }
    }
    .c_table_td:first-of-type {
      border-radius: 0 0 0 6px;

      @include sp {
        border-radius: 0 0 0 4px;
      }
    }
  }
}

/* =================================
 list
================================= */
// c_list_1
.c_list_1 {
  .c_item_1 {
    display: flex;
    .c_item_txt {
      margin: 0 0 0 5px;
    }
  }
}

.c_list_2 {
  margin: 30px 0 0;
  width: fit-content;

  @include sp {
    margin: 30px auto 0;
  }
}

// c_list_3
.c_list_3 {
  margin: 45px auto 0;

  @include sp {
    margin: 25px auto 0;
  }
  &._pt02 {
    margin: 50px auto 0;

    @include sp {
      margin: 20px auto 0;
    }
  }
}

/* =================================
 item
================================= */

// c_item_2
.c_item_2 {
  display: flex;

  @include sp {
    flex-direction: column;
  }
}

// c_item_3
.c_item_3 {
  display: flex;

  @include sp {
    flex-direction: column-reverse;
  }
}

// c_item_4
.c_item_4 {
  display: flex;
  align-items: center;
}
.c_item_4 + .c_item_4 {
  margin: 30px 0 0;

  @include sp {
    margin: 20px 0 0;
  }
}

// c_item_5
.c_item_5 {
  position: relative;
  margin: 0 auto;
  width: 1000px;

  @include sp {
    width: 100%;
  }
  &:not(:last-of-type) {
    &::before {
      position: absolute;
      bottom: -40px;
      left: 68px;
      z-index: -2;
      width: 4px;
      height: 40px;
      background: $color_23;
      content: "";

      @include sp {
        bottom: -30px;
        left: 35px;
        height: 30px;
      }
    }
    .c_item_inner {
      &::after {
        position: absolute;
        bottom: -21px;
        left: 50%;
        width: 68px;
        height: 34px;
        background-image: url(#{$img_dir}/common/arw-flow.svg);
        background-size: cover;
        background-repeat: no-repeat;
        content: "";
        transform: translate(-50%, 0);

        @include sp {
          bottom: -15px;
          width: 38px;
          height: 20px;
          background-image: url(#{$img_dir}/common/sp/arw-flow.svg);
        }
      }
    }
  }
  &::after {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: $color_32;
    content: "";
    opacity: .7;

    @include sp {
      border-radius: 10px;
    }
  }

  .c_item_inner {
    position: relative;
    padding: 30px;
    border-radius: 15px;
    background: $white;

    @include sp {
      padding: 15px;
      border-radius: 10px;
    }
    &._2 {
      padding: 15px 30px;

      @include sp {
        padding: 15px 10px;
      }
    }
    &._3 {
      padding: 5px 30px 30px;

      @include sp {
        padding: 15px 10px;
      }
    }
  }
  .flex {
    display: flex;
    align-items: center;
    &._2 {
      align-items: flex-start;

      @include sp {
        align-items: center;
      }
    }
  }
  .c_pic_flow {
    @include sp {
      flex-shrink: 0;
      width: 128px;
      height: 107px;
    }
    .img {
      @include sp {
        width: 100%;
      }
    }
    &._pt02 {
      @include sp {
        width: 129px;
        height: 108px;
      }
    }
  }
}
.c_item_5 + .c_item_5 {
  margin: 40px auto 0;

  @include sp {
    margin: 30px auto 0;
  }
}

/* =================================
 block
================================= */
.c_block_1,
.c_block_2 {
  padding: 50px 50px 60px;
  width: 730px;
  height: fit-content;
  border-radius: 20px;
  background: $white;

  @include sp {
    padding: 15px 20px 30px;
    width: 100%;
    border-radius: 10px;
  }
}
.c_block_1 {
  margin: 50px 0 0 -100px;

  @include sp {
    margin: -60px auto 0;
  }
  &._pt2 {
    margin: 90px 0 0 -80px;
    padding: 50px 50px 47px;
    width: 550px;

    @include sp {
      margin: -50px auto 0;
      padding: 10px 20px 20px;
      width: calc(100vw - 30px);
    }
  }
  &._pt3 {
    margin: 50px 0 0 -80px;
    padding: 50px 50px 47px;
    width: 550px;

    @include sp {
      margin: -60px auto 0;
      padding: 10px 20px 20px;
      width: calc(100vw - 30px);
    }
  }
  &._pt4 {
    margin: 90px 0 0 -80px;
    padding: 50px 50px 47px;
    width: 550px;

    @include sp {
      margin: -50px auto 0;
      padding: 10px 20px 25px;
      width: calc(100vw - 30px);
    }
  }
  &._pt5 {
    margin: 90px 0 0 -80px;
    padding: 50px 50px 47px;
    width: 730px;

    @include sp {
      margin: -50px auto 0;
      padding: 20px;
      width: calc(100vw - 30px);
    }
  }
}
.c_block_2 {
  position: relative;
  margin: 50px -100px 0 0;

  @include sp {
    margin: -60px auto 0;
  }
  &._pt2 {
    margin: 100px -80px 0 0;
    padding: 50px 50px 47px;
    width: 550px;

    @include sp {
      margin: -50px auto 0;
      padding: 10px 20px 20px;
      width: calc(100vw - 30px);
    }
  }
  &._pt3 {
    margin: 50px -80px 0 0;
    padding: 50px 50px 47px;
    width: 550px;

    @include sp {
      margin: -50px auto 0;
      padding: 10px 20px 20px;
      width: calc(100vw - 30px);
    }
  }
  &._pt4 {
    margin: 100px -80px 0 0;
    padding: 50px 50px 47px;
    width: 550px;

    @include sp {
      margin: -50px auto 0;
      padding: 10px 20px 25px;
      width: calc(100vw - 30px);
    }
  }
  &._pt5 {
    margin: 100px -80px 0 0;
    padding: 50px 50px 47px;
    width: 730px;

    @include sp {
      margin: -50px auto 0;
      padding: 10px 20px 25px;
      width: calc(100vw - 30px);
    }
  }
}

.c_block_3 {
  margin: 0 0 0 40px;

  @include sp {
    margin: 0 0 0 15px;
  }
  &._2 {
    margin: 25px 0 0 40px;

    @include sp {
      margin: 0 0 0 15px;
    }
  }
}

.c_block_4 {
  margin: 60px auto 0;
  padding: 45px 50px;
  width: 1000px;
  border-radius: 20px;
  background: $color_33;

  @include sp {
    margin: 25px auto 0;
    padding: 15px;
    width: calc(100% - 30px);
    border-radius: 10px;
  }
  &._pt02 {
    @include sp {
      width: 100%;
    }
  }
  .c_related_list {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0 0;

    @include sp {
      flex-direction: column;
      margin: 20px 0 0;
    }
    &._2 {
      margin: 25px 0 0;

      @include sp {
        margin: 5px 0 0;
      }
    }
  }
  .c_related_list + .c_related_list {
    margin: 12px 0 0;

    @include sp {
      margin: 0;
    }
  }
  .c_related_hospital_txt + .c_related_hospital_txt {
    margin: 0 0 0 30px;

    @include sp {
      margin: 5px 0 0;
    }
    &._2 {
      @include sp {
        margin: 8px 0 0;
      }
    }
  }
}

.c_block_5 {
  display: flex;
  padding: 15px 20px 22px;
  border-radius: 10px;
  background: $color_40;

  @include sp {
    padding: 5px 10px 13px;
    border-radius: 6px;
  }
}

/* =================================
 slider
================================= */
.c_slider {
  .slick-dots {
    display: flex;
    li {
      position: relative;
      display: inline-block;
      padding: 0;
      width: 20px;
      height: 20px;
      &.slick-active {
        button {
          &:before {
            background: #888888;
          }
        }
      }
      button {
        display: block;
        padding: 5px;
        width: 20px;
        height: 20px;
        border: 0;
        background: transparent;
        color: transparent;
        font-size: 0;
        line-height: 0;
        cursor: pointer;
        &:before {
          position: absolute;
          top: 6px;
          left: 6px;
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background: #dddddd;
          content: "";
          text-align: center;
          -webkit-font-smoothing: antialiased;
          font-size: 30px;
          line-height: 20px;
          opacity: 1;
        }
      }
    }
  }
}

.c_slider_1 {
}

.c_slider_2 {
}

.c_slider_3 {
}

/* =================================
 pager
================================= */
.c_pager_num {
}

.c_pager_prev_next {
}

/* =================================
 txt
================================= */

.c_link_1 {
  @include a_tag($color_23, $color_23);
}

.c_mv_sub_txt {
  position: relative;
  margin: 60px 0 0;

  @include sp {
    padding: 0 15px;
  }
}

.c_idea_txt {
  &.c_idea_txt_01 {
    margin: 0 0 0 15px;

    @include sp {
      margin: 0 0 0 10px;
    }
  }
  &.c_idea_txt_02 {
    margin: 0 0 0 15px;

    @include sp {
      margin: 0 0 0 15px;
    }
  }
  &.c_idea_txt_03 {
    margin: 0 0 0 15px;
  }
}

.c_map_txt_02 {
  position: relative;
  display: inline-block;
  margin: 0 20px 0 0;
  color: $color_23 !important;
  transition: all .3s;

  @include sp {
    width: fit-content;
  }
  &:hover {
    @include pc_tab {
      text-decoration: underline $color_23;
      opacity: .66;
      transition: all .3s;
    }
  }
  &::before {
    position: absolute;
    top: 0;
    right: -7px;
    width: 15px;
    height: 15px;
    background-image: url(#{$img_dir}/p-top/map-icon.svg);
    background-repeat: no-repeat;
    content: "";
    transform: translate(100%, 50%);

    @include sp {
    }
  }
  &._table {
    @include sp {
      margin: 0;
    }
  }
}

.c_sec_txt_1 {
  margin: 15px auto 0;

  @include sp {
    margin: 10px auto 0;
  }
  &._pt2 {
    @include sp {
      margin: 5px auto 0;
    }
  }
  &._pt3 {
    margin: 50px auto 0;

    @include sp {
      margin: 35px auto 0;
    }
  }
  &._pt4 {
    margin: 60px auto 0;

    @include sp {
      margin: 35px auto 0;
    }
  }
}

.c_symbol_item {
  display: flex;

  @include sp {
    margin: 5px 0 0;
  }
}
.c_symbol {
  flex-shrink: 0;
  margin: 12px 0 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $color_15;
  &._pt2 {
    width: 11px;
    height: 11px;
    background: $color_8;
  }
  &._pt3 {
    width: 8px;
    height: 8px;
    background: $color_8;
  }
  &._pt4 {
    margin: 9px 0 0;
    width: 11px;
    height: 11px;
    background: $color_8;
  }
}
.c_symbol_txt {
  margin: 0 0 0 8px;
}

.c_note {
  position: relative;
  padding: 0 0 0 20px;
  width: fit-content;

  @include sp {
    padding: 0 0 0 21px;
  }
  @include sp {
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "※";
  }
  &._2 {
    padding: 0 0 0 10px;
  }
}

.c_flow_txt {
  margin: 15px 0 0;

  @include sp {
    margin: 5px 0 0;
  }
  &._pt02 {
    position: relative;
    margin: 15px 0  0 20px;
    padding: 0 0 0 10px;

    @include sp {
      margin: 5px 0 0 27px;
    }
    &::before {
      position: absolute;
      top: 8px;
      left: 0;
      width: 17px;
      height: 17px;
      background: url(#{$img_dir}/common/call_icon.svg);
      content: "";
      transform: translate(-100%, 0);

      @include sp {
      }
    }
  }
}

/* =================================
 breadcrumb
================================= */
.c_breadcrumb {
  position: relative;
  display: flex;
  margin: 90px 0 0;
  padding: 15px 0 0;
  width: 1120px;
  border-top: 1px dotted $color_18;
  white-space: nowrap;

  @include sp {
    overflow-x: auto;
    margin: 15px 0 0;
    padding: 25px 0 0 15px;
    width: 100%;
    white-space: nowrap;
  }
  &._singlepage {
    position: relative;
    margin: 0 auto;
    padding: 0;
    width: 1120px;
    border-top: none;

    @include sp {
      margin: 30px auto 0;
      padding: 0 15px;
      width: 100%;
    }
    &::before {
      position: absolute;
      top: 48px;
      left: -187px;
      width: 150px;
      height: 252px;
      background: url(#{$img_dir}/common/header-single-obj.svg);
      content: "";

      @include sp {
        display: none;
      }
    }
    &::after {
      position: absolute;
      top: -185px;
      right: -260px;
      width: 450px;
      height: 350px;
      background: url(#{$img_dir}/common/header-bottom-obj.svg);
      content: "";

      @include sp {
        display: none;
      }
    }
  }
  .c_pagemap_top {
    position: relative;
    display: block;
    color: $color_15;
    text-decoration: underline;
    transition: all .3s;

    @include pc_tab {
      &:hover {
        text-decoration: none;
        opacity: .7;
        transition: all .3s;
      }
    }
    &::after {
      position: absolute;
      top: 12px;
      right: -16px;
      width: 7px;
      height: 11px;
      background: url(#{$img_dir}/common/bread_arw.svg)no-repeat;
      content: "";

      @include sp {
        top: 11px;
      }
    }
  }
  .c_pagemap {
    display: block;
    margin: 0 0 0 25px;
    transition: all .3s;
    &:not(:last-of-type) {
      position: relative;
      color: $color_15;
      text-decoration: underline;

      @include pc_tab {
        &:hover {
          text-decoration: none;
          opacity: .7;
          transition: all .3s;
        }
      }
      &::after {
        position: absolute;
        top: 12px;
        right: -16px;
        width: 7px;
        height: 11px;
        background: url(#{$img_dir}/common/bread_arw.svg)no-repeat;
        content: "";

        @include sp {
          top: 11px;
        }
      }
    }
  }
}

/* =================================
 block_診療案内
================================= */
.c_medical_info {
  position: relative;

  .sec_container {
    position: relative;
    margin: 235px auto 0;
    width: 1120px;

    @include sp {
      margin: 150px auto 0;
      width: 345px;
    }
    &::before {
      position: absolute;
      top: -165px;
      left: -45px;
      width: 1220px;
      height: 780px;
      background: url(#{$img_dir}/common/mefical-info-bg.png)no-repeat;
      content: "";

      @include sp {
        top: -96px;
        left: -78px;
        width: 1366px;
        height: 720px;
        background: url(#{$img_dir}/common/sp/mefical-info-bg.png)no-repeat;
      }
    }
  }
  .lead_txt {
    position: relative;
    margin: 60px auto 0;

    @include sp {
      margin: 40px auto 0;
    }
  }
  .sec_ttl_medical-info {
    @include sp {
    }
    &::before {
      top: -60px;
      left: 50%;
      width: 73px;
      height: 67px;
      background: url(#{$img_dir}/common/medical-info-ttl.svg)no-repeat;
      transform: translate(-50%, 0);

      @include sp {
        width: 63px;
        height: 58px;
        background: url(#{$img_dir}/common/sp/medical-info-ttl.svg)no-repeat;
      }
    }
  }
  .item_wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 65px auto 0;

    @include sp {
      flex-direction: column;
      margin: 35px auto 0;
    }
  }
  .item {
    position: relative;
    display: block;
    pointer-events: none;
    &::before {
      position: absolute;
      top: 8px;
      left: 8px;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      background: $color_20;
      content: "";
      transition: all .3s;
      pointer-events: none;

      @include sp {
        top: 6px;
        left: 6px;
        border-radius: 8px;
      }
    }
    &:hover {
      @include pc_tab {
        &::before {
          top: 5px;
          left: 5px;
          transition: all .3s;
        }
        .arw-icon {
          margin: 0 0 0 9px;
          transition: all .3s;
        }
        .department_ttl::before {
          width: 109%;
        }
      }
    }
  }
  .item + .item {
    margin: 0 0 0 30px;

    @include sp {
      margin: 15px 0 0;
    }
  }
  .item:first-of-type {
    @include sp {
      &::after {
        position: absolute;
        bottom: -55px;
        left: -35px;
        width: 80px;
        height: 80px;
        background-image: url(#{$img_dir}/common/sp/medical-info-obj-3.svg);
        content: "";
      }
    }
  }
  .item:nth-of-type(3) {
    margin: 30px 0 0 0;

    @include sp {
      margin: 15px 0 0;
      &::after {
        position: absolute;
        bottom: -85px;
        left: -60px;
        width: 120px;
        height: 120px;
        background-image: url(#{$img_dir}/common/sp/medical-info-obj-2.svg);
        content: "";
      }
    }
  }
  .item:last-of-type {
    position: relative;
    margin: 30px 0 0 30px;

    @include sp {
      margin: 15px 0 0;
      &::after {
        position: absolute;
        right: -70px;
        bottom: -55px;
        width: 115px;
        height: 115px;
        background-image: url(#{$img_dir}/common/sp/medical-info-obj-1.svg);
        content: "";
      }
    }
  }
  .item_inner {
    position: relative;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 20px;
    width: 545px;
    border-radius: 15px;
    background: $white;
    transition: all .3s;
    pointer-events: auto;

    @include sp {
      padding: 15px 12px;
      width: 100%;
      border-radius: 8px;
    }
    &:hover {
      @include pc_tab {
        top: 4px;
        left: 4px;
        transition: all .3s;
      }
      .item::before {
        // top: -4px;
        // left: -4px;
      }
    }
  }
  .lg_box {
    display: flex;
    background: $white;
  }
  .box_01 {
    @include sp {
      flex-shrink: 0;
      width: 100px;
      height: 67px;
    }
    .img {
      @include sp {
        width: 100%;
      }
    }
  }
  .box_02 {
    margin: 30px 0 0 16px;

    @include sp {
      margin: 0 0 0 15px;
    }
  }
  .head {
    display: flex;
    align-items: center;
    margin: 20px 0 0;

    @include sp {
    }
  }
  .department_icon {
    @include sp {
      width: 35px;
      height: 35px;
    }
    .img {
      @include sp {
        width: 100%;
      }
    }
  }
  .department_ttl {
    position: relative;
    z-index: 15;
    margin: 0 6px 0 15px;

    @include sp {
      margin: 0 6px 0 10px;
    }
    &:before {
      position: absolute;
      bottom: 0;
      left: -5px;
      z-index: -1;
      width: 0;
      height: 15px;
      border-radius: 4px;
      background: linear-gradient(to top, transparent 0, $color_34 0, $color_34 15px, transparent 15px);
      content: "";
      transition: width .3s ease-in-out;
    }
  }
  .arw-icon {
    transition: all .3s;

    @include sp {
    }
    .img {
      @include sp {
      }
    }
  }
  .sub_txt {
    margin: 5px 0 0;

    @include sp {
    }
  }
}

/* ===========================================
* block_診療予約
* ======================================== */
.c_reserve {
  background-color: $color_15;
  .sec_container {
    margin: 100px auto 0;
    padding: 40px 0 55px;
    width: 925px;

    @include sp {
      margin: 50px auto 0;
      padding: 35px 0 45px;
      width: 345px;
    }
  }
  .sec_ttl_wrapper_res {
    position: relative;
    margin: 12px 0 0 370px;

    @include sp {
      margin: 0 0 0 105px;
    }


    &::before {
      position: absolute;
      top: 0;
      left: -15px;
      width: 68px;
      height: 63px;
      background-image: url(#{$img_dir}/p-top/sec-07-ttl.svg);
      background-repeat: no-repeat;
      content: "";
      transform: translate(-100%, 0);

      @include sp {
        top: -10px;
        left: -11px;
        width: 58px;
        height: 54px;
        background-image: url(#{$img_dir}/p-top/sp/sec-07-ttl.svg);
      }
    }
    .c_sec_ttl_jp {
      position: relative;
      color: $white;
      font-size: 30px;
      line-height: calc(56 / 30);

      @include sp {
        font-size: 24px;
        line-height: calc(36 / 24);
      }
      &::after {
        top: calc(100% + 16px);
        left: 47%;
        transform: translate(-100%, 0);

        @include pc_tab {
          width: 42px;
          height: 6px;
          background: url(#{$img_dir}/p-top/sec-ttl-deco-pt02.png);
        }
        @include pc_large {
          width: 42px;
          height: 6px;
          background: url(#{$img_dir}/p-top/sec-ttl-deco-pt02.png);
        }
        @include sp {
          top: calc(100% + 10px);
          left: 45%;
        }
      }
    }
  }
  .res_wrapper {
    display: flex;
    margin: 60px auto 0;

    @include sp {
      flex-direction: column;
      margin: 47px auto 0;
    }
  }

  .box_01 {
    position: relative;

    @include sp {
    }
    &::before {
      position: absolute;
      top: 0;
      right: -80px;
      height: 190px;
      border-right: 1px solid rgba(213, 203, 180, .32);
      content: "";

      @include sp {
        top: calc(100% + 20px);
        right: 100%;
        width: 100%;
        height: 1px;
        border-bottom: 1px solid rgba(213, 203, 180, .32);
        transform: translate(100%, 0);
      }
    }
  }
  .box_02 {
    margin: 5px 0 0 162px;

    @include sp {
      margin: 45px auto 0;
    }
  }

  .small_box_wrapper {
    @include sp {
    }
  }


  .pic_res_icon {
    @include sp {
    }
    .img {
      @include sp {
      }
    }
  }
  .pic_content {
    margin: 5px 0 0 20px;

    @include sp {
    }
    .img {
      @include sp {
      }
    }
  }

  .btn_tel_wrapper {
    &::before {
      background-color: $color_25;
    }
  }
  .btn_web_wrapper {
    &::before {
      background-color: $color_26;
    }
  }


  .txt_01 {
    position: relative;
    margin: 10px 0 0 22px;

    @include sp {
      margin: 45px 0 0 21px;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "※";
      transform: translate(-100%, 0);

      @include sp {
      }
    }
  }
  .txt_02 {
    position: relative;
    margin: 25px 0 0 22px;

    @include sp {
      margin: 15px 0 0 21px;
      width: 325px;
    }
  }
}


/* ===========================================
* block_web、電話予約
* ======================================== */
.res_small_box {
  display: flex;

  @include sp {
    display: block;
  }
  .pic_tel {
    @include sp {
      margin: 0 40px 15px 0;
      width: fit-content;
    }
    .img {
      @include sp {
        width: 235px;
        height: 66px;
      }
    }
  }
  .pic_tel_time {
    margin: 13px auto 0;
    width: fit-content;
    .img {
      @include sp {
        width: 240px;
        height: 21px;
      }
    }
  }
  .btn_tel {
    background-color: $color_23;

    @include sp {
    }
  }
}

.c_web_txt {
  position: relative;
  margin: 0 0 0 35px;
  &::before {
    position: absolute;
    top: 0;
    left: -10px;
    width: 76px;
    height: 76px;
    background-image: url(#{$img_dir}/p-top/web-txt-img.png);
    background-size: cover;
    background-repeat: no-repeat;
    content: "";
    transform: translate(-100%, -50%);

    @include sp {
      top: 5px;
      width: 68px;
      height: 68px;
      background-image: url(#{$img_dir}/p-top/sp/web-txt-img.png);
    }
  }
  &::after {
    position: absolute;
    top: 50%;
    right: -71px;
    width: 8px;
    height: 13px;
    background-image: url(#{$img_dir}/p-top/web-arw.svg);
    background-size: cover;
    background-repeat: no-repeat;
    content: "";
    transform: translate(0, -50%);

    @include sp {
      right: -50px;
    }
  }
  &.web_txt_header {
    margin: 0;
    &::before {
      display: none;
    }
    &:after {
      right: -40px;
    }
  }
}

/* ===========================================
* 採用バナー
* ======================================== */
.c_recruit_bnr {
  position: relative;
  z-index: 10;
  display: flex;
  margin: 60px auto 0;
  width: fit-content;
  height: 200px;
  border-radius: 10px;
  background-color: $white;
  box-shadow: 8px 8px 0 0 #e6e2d2;
  transition: opacity .3s;

  @include sp {
    flex-direction: column-reverse;
    margin: 40px auto 0;
    width: 345px;
    height: auto;
    box-shadow: 4px 4px 0 0 #e6e2d2;
  }
  &._2 {
    margin: 40px auto 0;

    @include sp {
      margin: 30px auto 0;
    }
  }

  &:hover {
    @include pc_tab {
      opacity: .7;
      transition: opacity .3s;
    }
  }

  &::before {
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border: 1px solid $color_6;
    border-radius: 8px;
    content: "";
  }
  .sec_ttl_wrapper_recruit {
    position: relative;
    margin: 36px 0 0 65px;

    @include sp {
      margin: 25px 0 0 130px;
    }
    &._2 {
      @include sp {
        margin: 25px auto 0;
      }
      &::before {
        width: 46px;
        height: 41px;
        background-image: url(#{$img_dir}/p-cancer/bnr-icon.svg);
        background-size: cover;
        background-repeat: no-repeat;

        @include sp {
          width: 38px;
          height: 34px;
          background-image: url(#{$img_dir}/p-cancer/sp/bnr-icon.svg);
        }
      }
    }
    &::before {
      position: absolute;
      top: 0;
      left: -10px;
      width: 55px;
      height: 34px;
      background-image: url(#{$img_dir}/p-top/h2ttl-img.png);
      content: "";
      transform: translate(-100%, 0);

      @include sp {
        top: -5px;
        left: -9px;
      }
    }
    &::after {
      position: absolute;
      top: 6px;
      right: -8px;
      width: 18px;
      height: 18px;
      background-image: url(#{$img_dir}/p-top/h2ttl-arrow.png);
      background-size: cover;
      content: "";
      transform: translate(100%, 0);

      @include sp {
        top: 3px;
      }
    }
  }
  .box_contents {
    padding: 0 40px;
    width: 440px;
    border-radius: 10px 0 10px 10px;
    background-color: $white;

    @include sp {
      padding: 0 20px 20px;
      width: 100%;
    }
    &._check {
      padding: 50px 80px;

      @include sp {
        padding: 0 20px 20px;
        width: 100%;
      }
    }
  }
  .box_pic {
    @include sp {
      width: 100%;
    }
  }

  .txt_01 {
    margin: 12px 0 0;

    @include sp {
      margin: 20px 0 0;
    }
  }
  .pic {
    @include sp {
      width: 100%;
    }
    .img {
      @include sp {
        width: 345px;
        height: 168px;
        border-radius: 10px;
      }
    }
  }
}

/* ===========================================
* カテゴリー一覧
* ======================================== */
.c_side_category {
  flex-shrink: 0;
  margin: 10px 0 0 -70px;
  width: 300px;
  height: fit-content;

  @include sp {
    margin: 60px auto 0;
    width: 100%;
  }
  &._singlepage {
    margin: 0 0 0 70px;

    @include sp {
      margin: 60px auto 0;
    }
  }
  .c_cat_ttl {
    position: relative;
    padding: 0 0 15px;
    border-bottom: 2px solid $color_32;

    @include sp {
    }
  }
  .c_cat_list {
    @include sp {
    }
  }
  .c_cat_item {
    @include sp {
    }
    .c_cat_link {
      position: relative;
      display: block;
      padding: 18px 0 17px;
      border-bottom: 1px dotted $color_18;
      transition: all .3s;

      @include sp {
        padding: 10px 0 8px;
      }
      &:hover {
        @include pc_tab {
          opacity: .7;
          transition: all .3s;
        }
      }
      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 6px;
        height: 10px;
        background: url(#{$img_dir}/common/arw-sidebar.svg)no-repeat;
        content: "";
        transform: translate(0, -50%);

        @include sp {
        }
      }
    }
  }
}

/* ===========================================
* ページャー
* ======================================== */
.c_pager {
  display: flex;
  margin: 70px 0 0 260px;
  width: fit-content;

  @include sp {
    margin: 30px auto 0;
  }
  .c_btn_wrapper {
    display: flex;
    flex-wrap: nowrap;

    @include sp {
    }
    &.pager_flex {
      @include sp {
        flex-direction: row;
      }
    }
  }

  .c_btn_pager {
    display: block;

    @include sp {
      width: 41px;
      height: 41px;
      .img {
        width: 100%;
        height: auto;
      }
    }
  }

  .c_btn_prev,
  .c_btn_last {
    margin: 0 0 0 25px;

    @include sp {
      margin: 0 0 0 3px;
    }
  }
  .c_btn_first {
    @include pc_tab {
      width: 52px;
      height: 54px;
      background: url(#{$img_dir}/common/page-first.png)no-repeat;
      transition: all .3s;
    }
    &:hover {
      @include pc_tab {
        background: url(#{$img_dir}/common/page-first-h.svg)no-repeat;
        transition: all .3s;
        transform: translate(-5px, 0);
      }
    }
  }
  .c_btn_prev {
    @include pc_tab {
      width: 52px;
      height: 54px;
      background: url(#{$img_dir}/common/page-prev.png)no-repeat;
      transition: all .3s;
    }
    &:hover {
      @include pc_tab {
        background: url(#{$img_dir}/common/page-prev-h.svg)no-repeat;
        transition: all .3s;
        transform: translate(-5px, 0);
      }
    }
  }
  .c_btn_next {
    @include pc_tab {
      width: 52px;
      height: 54px;
      background: url(#{$img_dir}/common/page-next.png)no-repeat;
      transition: all .3s;
    }
    &:hover {
      @include pc_tab {
        background: url(#{$img_dir}/common/page-next-h.svg)no-repeat;
        transition: all .3s;
        transform: translate(5px, 0);
      }
    }
  }
  .c_btn_last {
    @include pc_tab {
      width: 52px;
      height: 54px;
      background: url(#{$img_dir}/common/page-last.png)no-repeat;
      transition: all .3s;
    }
    &:hover {
      @include pc_tab {
        background: url(#{$img_dir}/common/page-last-h.svg)no-repeat;
        transition: all .3s;
        transform: translate(5px, 0);
      }
    }
  }
  .c_num_list {
    display: flex;
    margin: 0 30px;
    counter-reset: page-count 0;

    @include sp {
      margin: 0 4px;
    }
  }
  .c_num_item:not(:first-of-type) {
    .c_page_link {
      margin: 0 0 0 15px;

      @include sp {
        margin: 0 0 0 -10px;
      }
    }
  }
  .c_num_item {
    display: flex;
    align-items: center;
    justify-content: center;

    @include sp {
    }
    .c_page_link {
      position: relative;
      display: block;
      width: 50px;
      height: 50px;
      transition: all .3s;

      @include sp {
        width: 39px;
        height: 39px;
      }
      @include a_tag($color_15, $color_15);
      &._current {
        margin: 0 0 0 10px;
        border-radius: 50%;
        background: $color_15;
        color: $white !important;

        @include sp {
          margin: 0 5px 0 -5px;
        }
      }
      &::after {
        position: absolute;
        top: 20%;
        left: 50%;
        content: counter(page-count)"";
        counter-increment: page-count 1;
        transform: translate(-50%, 0);

        @include sp {
          top: 20%;
        }
      }
    }
    &:hover {
      @include pc_tab {
        opacity: .7;
        transition: all .3s;
      }
    }
  }
}

/* ===========================================
* 投稿のカテゴリー
* ======================================== */
.c_cat_box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  min-height: 26px;
  width: 100px;
  border-radius: 6px;
  background-color: $color_17;

  @include sp {
    min-height: 21px;
    width: 102px;
    border-radius: 4px;
  }
  &._single-page {
    padding: 2px 8px;
    width: initial;

    @include sp {
      max-width: 200px;
    }
  }
  span {
    display: block;
    text-align: center;
  }
}

/* ===========================================
* スクロールボタン
* ======================================== */
.c_btn_wrapper {
  display: flex;
  flex-wrap: wrap;

  @include sp {
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
  }
  &._pt02 {
    flex-direction: row;
  }
  &._pt03 {
    @include sp {
      flex-direction: row;
    }
  }
}

/* =================================
 ヘッダー、フッター_バナー
================================= */
.c_bnr_list {
  display: flex;
  margin: 20px 0 0;

  @include sp {
    margin: 15px 0 0;
  }
  .bnr_item {
    display: block;
    width: fit-content;
    transition: all .3s;
    &:hover {
      @include pc_tab {
        opacity: .7;
        transition: all .3s;
      }
    }
    .img {
      @include sp {
        width: 170px;
        height: 71px;
      }
    }
  }
  .bnr_item + .bnr_item {
    margin: 0 0 0 15px;

    @include sp {
      margin: 0 0 0 5px;
    }
  }
}
