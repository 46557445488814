//======================================================================
// variable
//======================================================================
// imgディレクトリまでのパス
$img_dir: "../img";

// コンテンツ幅
$content_width: 1200px;

// SP時の左右の余白（片方）
$sp_side_padding: 20px;

//=================================
// breakpoint
//=================================
// 下記2つの変数の間がタブレット表示
// タブレット表示なしの場合は2つの変数の値を「1px差」で設定すること

// $pc_bp以上がPC表示
$pc_bp: 1025px;

// $sp_bp以下がSP表示
$sp_bp: 767px;

// $1367px以上
$pc_bp_large: 1367px;

// $370px以下
$sp_small: 370px;

//=================================
// font
//=================================
$font_1: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", YuGothic, sans-serif;
$font_2: "Roboto", sans-serif;
$font_3: "游ゴシック", YuGothic, sans-serif;
$font_4: "Zen Maru Gothic", sans-serif;;
$font_5: "Noto Sans JP", sans-serif;

//=================================
// color
//=================================
$black: #000000;

$white: #ffffff;

$color_1: #535353;

$color_2: #000000;

$color_3: #000000;

$color_4: #000000;

$color_5: #000000;

$color_6: #f8f5ec;

$color_7: #e6e2d2;

$color_8: #eb7696;

$color_9: #f8f5ec;

$color_10: #535353;

$color_11: #999999;

$color_12: #eb7696;

$color_13: #ef9e59;

$color_14: #bfb8a4;

$color_15: #72684e;

$color_16: #f0e2b6;

$color_17: #f4e8cb;

$color_18: #d0d0d0;

$color_19: #f7abc0;

$color_20: #d8d1bc;

$color_21: #efc359;

$color_22: #efefef;

$color_23: #baa571;

$color_24: #d5cbb4;

$color_25: #91825b;

$color_26: #cb6480;

$color_27: #f0f0f0;

$color_28: #a39b85;

$color_29: #92886e;

$color_30: #999999;

$color_31: #00000010;

$color_32: #d9d3c2;

$color_33: #fefdfb;

$color_34: #fccad8;

$color_35: #f2f2f2;

$color_36: #707070;

$color_37: #a6a6a6;

$color_38: #ece7dc;

$color_39: #e04347;

$color_40: #f5f4f0;

//=================================
// CSS Transition Easing
//=================================
// Default
$linear: cubic-bezier(.250, .250, .750, .750);
$ease: cubic-bezier(.250, .100, .250, 1.000);
$ease-in: cubic-bezier(.420, .000, 1.000, 1.000);
$ease-out: cubic-bezier(.000, .000, .580, 1.000);
$ease-in-out: cubic-bezier(.420, .000, .580, 1.000);
// In
$easeInQuad: cubic-bezier(.550, .085, .680, .530);
$easeInCubic: cubic-bezier(.550, .055, .675, .190);
$easeInQuart: cubic-bezier(.895, .030, .685, .220);
$easeInQuint: cubic-bezier(.755, .050, .855, .060);
$easeInSine: cubic-bezier(.470, .000, .745, .715);
$easeInExpo: cubic-bezier(.950, .050, .795, .035);
$easeInCirc: cubic-bezier(.600, .040, .980, .335);
$easeInBack: cubic-bezier(.600, -.280, .735, .045);
// Out
$easeOutQuad: cubic-bezier(.250, .460, .450, .940);
$easeOutCubic: cubic-bezier(.215, .610, .355, 1.000);
$easeOutQuart: cubic-bezier(.165, .840, .440, 1.000);
$easeOutQuint: cubic-bezier(.230, 1.000, .320, 1.000);
$easeOutSine: cubic-bezier(.390, .575, .565, 1.000);
$easeOutExpo: cubic-bezier(.190, 1.000, .220, 1.000);
$easeOutCirc: cubic-bezier(.075, .820, .165, 1.000);
$easeOutBack: cubic-bezier(.175, .885, .320, 1.275);
// In Out
$easeInOutQuad: cubic-bezier(.455, .030, .515, .955);
$easeInOutCubic: cubic-bezier(.645, .045, .355, 1.000);
$easeInOutQuart: cubic-bezier(.770, .000, .175, 1.000);
$easeInOutQuint: cubic-bezier(.860, .000, .070, 1.000);
$easeInOutSine: cubic-bezier(.445, .050, .550, .950);
$easeInOutExpo: cubic-bezier(1.000, .000, .000, 1.000);
$easeInOutCirc: cubic-bezier(.785, .135, .150, .860);
$easeInOutBack: cubic-bezier(.680, -.550, .265, 1.550);


//======================================================================
// mixin
//======================================================================

//=================================
// clearfix
//=================================
@mixin cf {
  &:after {
    display: block;
    clear: both;
    content: "";
  }
}

//=================================
// safe-area
//=================================
@mixin safe_area {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-right: constant(safe-area-inset-right);
  padding-right: env(safe-area-inset-right);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: constant(safe-area-inset-left);
  padding-left: env(safe-area-inset-left);
}

//=================================
// aタグのカラー変更
//=================================
@mixin a_tag($color1, $color2) {
  &:link,
  &:active,
  &:visited {
    color: $color1;
    cursor: pointer;
  }
  &:hover {
    color: $color2;
  }
}

// @mixin a_tag_2($color1, $color2) {
//   &:link,
//   &:active,
//   &:visited {
//     color: $color23;
//     cursor: pointer;
//   }
//   &:hover {
//     color: $color23;
//   }
// }

//=================================
// プレースホルダーのカラー変更
//=================================
@mixin placeholder($color) {
  &:placeholder-shown {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

//=================================
// media query
//=================================
@mixin mq($min_width, $max_width) {
  @media screen and (min-width: $min_width + px) and (max-width: $max_width + px) {
    @content;
  }
}
@mixin mq_min($width) {
  @media screen and (min-width: $width + px) {
    @content;
  }
}
@mixin mq_max($width) {
  @media screen and (max-width: $width + px) {
    @content;
  }
}

// device
@mixin pc {
  @media screen and (min-width: $pc_bp) {
    @content;
  }
}
@mixin sp {
  @media screen and (max-width: $sp_bp) {
    @content;
  }
}
@mixin tab {
  @media screen and (min-width: $sp_bp + 1px) and (max-width: $pc_bp - 1px) {
    @content;
  }
}
@mixin pc_tab {
  @media screen and (min-width: $sp_bp + 1px) {
    @content;
  }
}
@mixin sp_tab {
  @media screen and (max-width: $pc_bp - 1px) {
    @content;
  }
}
@mixin pc_content {
  @media screen and (min-width: $pc_bp) and (max-width: $content_width) {
    @content;
  }
}
@mixin pc_large {
  @media screen and (min-width: $pc_bp_large) {
    @content;
  }
}
@mixin sp_small {
  @media screen and (max-width: $sp_small) {
    @content;
  }
}

//=================================
// css hack
//=================================
// Chrome(and Safari, Opera)
@mixin chrome {
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @content;
  }
}
// FireFox
@mixin firefox {
  _:lang(x)::-moz-placeholder,
  & {
    @content;
  }
}
// Edge
@mixin edge {
  _:lang(x)::-ms-,
  & {
    @content;
  }
}
// IE(11)
@mixin ie {
  _:-ms-lang(x)::-ms-backdrop,
  & {
    @content;
  }
}
// Safari
@mixin safari {
  _:lang(x) + _:-webkit-full-screen-document,
  & {
    @content;
  }
}

//=================================
// object fit images
//=================================
@mixin ofi($size) {
  object-fit: $size;

  @include ie {
    font-family: "object-fit: #{$size};";
  }
}

//=================================
// hide scrollbar
//=================================
@mixin hide_scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

//=================================
// font size
//=================================
@mixin fz($size, $height, $spacing: false) {
  font-size: $size + px;
  line-height: calc($height / $size);
  @if $spacing {
    letter-spacing: $spacing + px;
  }
}
