@use "setting" as *;

/* ======================================================================
 layout
====================================================================== */

/* =================================
 l_container
================================= */
.l_container {
  overflow: hidden;
  min-width: 1366px;
  background-color: $color_6;

  @include sp {
    min-width: inherit;
  }
  @include pc_large {
    min-width: 1511px;
  }
}

/* =================================
 l_header
================================= */
.l_header_pc {
  position: relative;
  width: fit-content;

  @include pc_large {
    margin: 0 auto;
    padding-left: 40px;
    max-width: 1600px;
    width: 100%;
  }
  &::before {
    position: absolute;
    top: -40px;
    right: 30px;
    width: 373px;
    height: 283px;
    background: url(../img/p-top/header-obj-set.svg) no-repeat;
    content: "";
    transform: translate(100%, 0);

    @include pc_large {
      top: -20px;
      right: 23.7%;
    }
  }
}

// pc_nav
.l_header_pc {
  .nav {
    margin: 22px 0 0;
  }
  .nav_list {
    display: flex;
  }
  .nav_item {
    position: relative;
    transition: opacity .3s;
    &::before {
      position: absolute;
      top: 37%;
      right: -10px;
      width: 8px;
      height: 10px;
      background: url(../img/p-top/header-arw.svg) no-repeat;
      content: "";
      transition: all .3s;
      transform: translate(50%, 0);

      @include sp {
      }
    }
    &:hover {
      @include pc_tab {
        opacity: .7;
        transition: opacity .3s;
        &::before {
          right: -13px;
          transition: right .3s;
        }
      }
    }
  }
  .nav_item + .nav_item {
    margin: 0 0 0 45px;
  }

  // pc_マウスオーバーメニュー
  .sub_menu_wrapper {
    position: absolute;
    left: 50%;
    display: none;
    width: 415px;
    height: 62px;
    background: url(#{$img_dir}/p-top/header_sub-menu-wrapper.png);
    background-size: cover;
    transform: translate(-50%, 0);
  }
  .sub_menu_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 0 -18px;
  }
  .sub_menu_inner {
    display: flex;
  }
  .link_txt_sub + .link_txt_sub {
    margin: 0 0 0 45px;
  }
  .nav_item_toggle {
    position: relative;
    top: 0;
    left: 0;
    &.is-open {
      opacity: 1;
    }
    &:before {
      top: 44%;
      transition: all .3s;
      transform: translate(50%, 0) rotate(90deg);
    }
    &:hover {
      .link_txt {
        opacity: .7;
        transition: all .3s;
      }
      &:before {
        top: 52%;
        right: -10px;
        transition: all .3s;
      }
    }
  }
}
//sp_追従ボタン
.fixed_btn_wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 40;
  display: flex;
  width: 100vw;
  height: 60px;
  background: $white;
  .fixed_btn_tel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37.33%;
    background: $color_23;
    transition: all .3s;
    &:hover {
      @include pc_tab {
        opacity: .7;
        transition: all .3s;
      }
    }
    .img {
      width: 100px;
      height: 39px;
    }
  }
  .fixed_btn_res {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37.33%;
    background: $color_12;
    transition: all .3s;
    &:hover {
      @include pc_tab {
        opacity: .7;
        transition: all .3s;
      }
    }
    .img {
      width: 104px;
      height: 42px;
    }
  }
  .fixed_btn_access {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 37.33% - 37.33%);
    background: $white;
    transition: all .3s;
    &:hover {
      @include pc_tab {
        opacity: .7;
        transition: all .3s;
      }
    }
    .img {
      width: 50px;
      height: 40px;
    }
  }
}

.btn_web {
  background-color: $color_12;

  @include sp {
  }
  &:hover {
    @include pc_tab {
      background-color: rgba(235, 118, 150, 0);
    }
  }
}
.link_txt_sub {
  position: relative;
  display: block;
  padding: 1px 0 0;
  transition: opacity .3s;
  &::before {
    position: absolute;
    top: 37%;
    right: -10px;
    width: 8px;
    height: 10px;
    background: url(../img/p-top/header-arw.svg) no-repeat;
    content: "";
    transform: translate(50%, 0);

    @include sp {
    }
  }
  &:hover {
    @include pc_tab {
      opacity: .7;
      transition: opacity .3s;
    }
  }
}

// スクロール時_nav
.l_header_pc {
  .header_fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s;
    &.is_open {
      visibility: visible;
      opacity: 1;
    }
  }
  .header_fixed_inner {
    display: flex;
    align-items: center;
    min-width: 1293px;
    width: 100vw;
    height: 90px;
    background: $white;
    box-shadow: 0 2px 6px $color_31;
    .pic_company {
      margin: 0 0 0 15px;
      transition: all .3s;
      &:hover {
        @include pc_tab {
          opacity: .7;
          transition: all .3s;
        }
      }
    }
    .nav {
      margin: 0 0 0 20px;
      .nav_item {
        &::after {
          position: absolute;
          top: 50%;
          right: -27px;
          width: 1px;
          height: 40px;
          border-right: 1px solid $color_35;
          content: "";
          transform: translate(0, -50%);

          @include sp {
          }
        }
        &:last-of-type {
          &::after {
            display: none;
          }
        }
      }
      .nav_item + .nav_item {
        margin: 0 0 0 37px;

        @include pc_large {
          margin: 0 0 0 40px;
        }
      }
    }
    .btn_wrapper {
      display: flex;
      align-items: center;
      margin: 0 0 0 auto;
      padding: 0 0 0 15px;
      border-left: 1px solid $color_35;

      @include pc_large {
      }

      .link_box {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s;
        &.link_box_res {
          width: 160px;
          height: 90px;
          background: $color_8;
          transition: all .3s;
          &:hover {
            @include pc_tab {
              background: $color_26;
              transition: all .3s;
            }
            .inner {
              .txt::after {
                @include pc_tab {
                  right: -5px;
                  transition: all .3s;
                }
              }
            }
          }
          .inner {
            .txt {
              position: relative;
              margin: 5px auto 0;
              padding: 0 9px 0 0;
              &::after {
                position: absolute;
                top: 50%;
                right: 0;
                width: 6px;
                height: 10px;
                background: url(../img/p-top/arw-header-res.svg) no-repeat;
                content: "";
                transition: all .3s;
                transform: translate(100%, -50%);

                @include sp {
                }
              }
            }
          }
        }
      }
      .link_box + .link_box {
        margin: 0 0 0 15px;
      }
    }
  }
}

body > div:not(#p_top) {
  .header_container {
    display: none;
    visibility: hidden;
  }
  .l_header_pc::before {
    display: none;
  }
}

.header_container {
  position: relative;
  left: -20px;
  z-index: 40;
  padding: 10px 40px 20px 60px;
  border-radius: 0 0 30px 30px;
  background: $white;
  box-shadow: 0 2px 6px $color_31;

  @include pc_large {
    padding: 10px 33px 20px;
    width: 1200px;
  }
  &.is_close {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}

.header_container {
  .box {
    display: flex;
  }
  .company_logo {
  }
  .pic_tel {
    display: block;
    margin: 7px 0 0 138px;
    width: fit-content;
    transition: all .3s;
  }



  .pic_company {
    display: block;
    margin: 10px 0 0;
    transition: opacity .3s;
    .img {
      width: 302px;
      height: 39px;
    }
    &:hover {
      @include pc_tab {
        opacity: .7;
        transition: opacity .3s;
      }
    }
  }
  .btn_header-web_wrapper {
    margin: 12px 0 0 10px;
    &::before {
      background: $color_26 ;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 50%;
      width: 122px;
      height: 24px;
      background: url(../img/p-top/header-web-obj.png) no-repeat;
      content: "";
      transition: top .3s;
      transform: translate(-50%, -50%);

      @include sp {
      }
    }
  }
  .btn_header-web_wrapper:hover {
    &::after {
      top: 3px;
      transition: top .3s;
    }
  }
}

.l_header_sp {
  position: relative;
  z-index: 50;
  &::after {
    position: absolute;
    content: "";

    @include sp {
      position: absolute;
      top: -10px;
      right: -80px;
      width: 165px;
      height: 159px;
      background: url(../img/common/sp/header-sp-obj.png) no-repeat;
      content: "";
    }
  }
}

.l_header_sp {
  .l_heade_top {
    display: flex;
    justify-content: space-between;
  }
  .box {
    padding: 8px 25px 12px 15px;
    width: 295px;
    border-radius: 0 0 13px 0;
    background: $white;
    filter: drop-shadow(0 2px 6px $color_27);
  }
  .pic_company {
    display: block;
    margin: 8px 0 0;
    width: fit-content;
    .img {
      width: 255px;
      height: 33px;
    }
  }
  .icon_menu {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 20;
    width: 56px;
    height: 57px;
    background-image: url(#{$img_dir}/p-top/sp/menu-close.svg);

    &.is-active {
      background-image: url(#{$img_dir}/p-top/sp/menu-active.svg);
    }
  }
  .hum_menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    display: none;
    padding: 45px 0 40px;
    width: 100vw;
    background: $color_15;
    opacity: .98;
    &.is-active {
      overflow-y: auto;
      height: 100vh;
    }
  }
  .hum_menu_container {
    margin: 10px auto 0;
    width: 325px;
  }
  .hum_menu_container_02 {
    margin: 10px auto 0;
    width: 345px;
    .txt_01 {
      position: relative;
      margin: 45px 0 0 20px;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "※";
        transform: translate(-100%, 0);
      }
    }
  }
  .lg_box {
    margin: 10px auto 0;
    padding: 35px 0 0;
    border-top: 1px solid $color_29;
    .txt_02 {
      margin: 15px auto 0;
    }
  }
  .lg_box + .lg_box {
    margin: 20px auto 0;
    padding: 25px 0 30px;
    border-bottom: 1px solid $color_29;
  }
  .icon_menu_close {
    margin: 0 0 0 auto;
    width: fit-content;
  }
  .nav {
    margin: 10px auto 0;
  }
  .nav_list {
  }
  .nav_item {
    position: relative;
    padding: 15px 0;
    &::before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 7px;
      height: 11px;
      background: url(#{$img_dir}/p-top/sp/hum-lnk-arw.svg) no-repeat;
      content: "";
      transform: translate(0, -50%);

      @include sp {
      }
    }
    .link_txt {
      display: block;
      color: $white;
      transition: opacity .3s;
      &._first {
        position: relative;
        padding: 0 0 15px;
        border-top: none;
        &::before {
          position: absolute;
          top: 30%;
          right: 0;
          width: 7px;
          height: 11px;
          background: url(#{$img_dir}/p-top/sp/hum-lnk-arw.svg) no-repeat;
          content: "";
          transform: translate(0, -50%);

          @include sp {
          }
        }
      }
      &:hover {
        @include pc_tab {
          opacity: .7;
          transition: opacity .3s;
        }
      }
    }
    .link_txt_mini {
      display: block;
      padding: 15px 0 15px 20px;
      border-top: 1px dashed $color_28;
      color: $white;
    }
    .link_txt_mini:last-of-type {
      padding: 15px 0 0 20px;
    }
  }
  .nav_item + .nav_item {
    border-top: 1px dashed $color_28;
  }
  .nav_item_pt02 {
    position: relative;
    padding: 0 0 15px;
    &::before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 7px;
      height: 11px;
      background: url(#{$img_dir}/p-top/sp/hum-lnk-arw.svg) no-repeat;
      content: "";
      transform: translate(0, -100%);

      @include sp {
      }
    }
  }
  .nav_item_pt03 {
    position: relative;
    &::before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 7px;
      height: 11px;
      background: url(#{$img_dir}/p-top/sp/hum-lnk-arw.svg) no-repeat;
      content: "";

      @include sp {
      }
    }
  }
  .ribbonn_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto 0;
    width: 430px;
    height: 110px;
    border-radius: 4px;
    background-color: $color_9;

    @include sp {
      margin: 25px auto 0;
      width: 100%;
    }
    .ribbonn_box_inner {
      display: flex;
      width: 390px;
      height: 64px;

      @include sp {
        width: 315px;
        height: 88px;
      }
    }
    .pic_ribbonn {
      width: fit-content;
      .img {
        @include sp {
          width: 26px;
          height: 40px;
        }
      }
    }
    .txt_wrapper {
      margin: 0 0 0 15px;
    }
    .txt_02 {
      @include sp {
      }
    }
    .txt_03 {
      @include sp {
      }
    }
  }
  .ribbonn_box_header {
    height: 124px;
  }
}


/* =================================
 l_breadcrumbs
================================= */
.l_breadcrumbs {
}

/* =================================
 l_main_visual
================================= */
// l_main_visual_top
.l_main_visual_top {
  position: relative;
  z-index: 30;
  padding: 20px 0 0;

  @include sp {
    margin: -10px 0 0;
    padding: 0;
  }
  &._underlayer {
    padding: 107px 0 0;

    @include sp {
      padding: 0;
    }
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 450px;
      height: 350px;
      background: url(#{$img_dir}/common/header-bottom-obj.svg);
      content: "";

      @include sp {
        display: none;
      }
    }
  }

  .mv_container {
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    min-width: 1366px;
    max-width: 1600px;

    @include sp {
      flex-direction: column;
      min-width: inherit;
      max-width: inherit;
      width: 100vw;
    }
    &::after {
      @include sp {
        position: absolute;
        top: -35px;
        left: -115px;
        width: 534px;
        height: 480px;
        background: url(#{$img_dir}/p-top/sp/mv-obj_set.svg);
        content: "";
      }
    }
    &._underlayer {
      justify-content: inherit;
      min-width: inherit;
      width: 1120px;

      @include sp {
        flex-direction: column-reverse;
        width: 100%;
      }
      &::before {
        @include pc_tab {
          position: absolute;
          top: 47px;
          left: 570px;
          width: 505px;
          height: 372px;
          background: url(#{$img_dir}/p-faq/mv-main-img_bg.png);
          content: "";
        }
      }
      &::after {
        @include sp {
          display: none;
        }
      }
    }
  }
  .mv_box_01 {
    position: relative;
    z-index: 10;
    flex-shrink: 0;
    margin: 110px 40px 0 4%;

    @include sp {
      margin: 50px 0 0 18px;
    }
    &._underlayer {
      margin: 0;
      width: 550px;

      @include sp {
        margin: 40px 0 0;
        width: 100%;
      }
      &::before {
        position: absolute;
        top: -15px;
        left: -190px;
        width: 819px;
        height: 536px;
        background: url(#{$img_dir}/common/mv_obj.png);
        content: "";

        @include sp {
          top: -44px;
          left: -60px;
          width: 513px;
          height: 377px;
          background: url(#{$img_dir}/common/sp/mv_obj.png);
        }
      }
    }
  }
  .mv_box_02 {
    position: relative;
    z-index: 5;
    display: flex;

    @include sp {
      margin: -20px auto 0;
      width: 100%;
      height: 219px;
    }
    &::before {
      position: absolute;
      top: -30px;
      left: -70px;
      width: 107px;
      height: 109px;
      background: url(#{$img_dir}/p-top/mv-img-obj.png);
      content: "";

      @include sp {
        display: none;
      }
    }
    &::after {
      position: absolute;
      top: -20px;
      left: -665px;
      z-index: -1;
      width: 729px;
      height: 741px;
      background: url(#{$img_dir}/p-top/mv-obj_set.png);
      content: "";
    }
    &._top {
      @include sp {
        margin: 30px auto 0;
      }
    }
    &._underlayer {
      z-index: 15;
      display: block;
      &::before {
        display: none;
      }
      &::after {
        position: absolute;
        top: 0;
        left: 40px;
        width: 520px;
        height: 347px;
        content: "";

        @include sp {
          left: -58px;
          z-index: 10;
          width: 448px;
          height: 274px;
        }
      }
      &._about {
        &::after {
          @include pc_tab {
            background: url(#{$img_dir}/p-about/mv-main-img.png);
          }
          @include sp {
            background: url(#{$img_dir}/p-about/sp/mv-main-img.png)no-repeat left top/cover;
          }
        }
      }
      &._faq {
        &::after {
          @include pc_tab {
            background: url(#{$img_dir}/p-faq/mv-main-img.png);
          }
          @include sp {
            background: url(#{$img_dir}/p-faq/sp/mv-main-img.png)no-repeat left top/cover;
          }
        }
      }
      &._access {
        &::after {
          @include pc_tab {
            background: url(#{$img_dir}/p-access/mv-main-img.png);
          }
          @include sp {
            background: url(#{$img_dir}/p-access/sp/mv-main-img.png)no-repeat left top/cover;
          }
        }
      }
      &._archive-column {
        &::after {
          @include pc_tab {
            background: url(#{$img_dir}/archive-column/mv-main-img.png);
          }
          @include sp {
            background: url(#{$img_dir}/archive-column/sp/mv-main-img.png)no-repeat left top/cover;
          }
        }
      }
      &._archive-news {
        &::after {
          @include pc_tab {
            background: url(#{$img_dir}/archive-news/mv-main-img.png);
          }
          @include sp {
            background: url(#{$img_dir}/archive-news/sp/mv-main-img.png)no-repeat left top/cover;
          }
        }
      }
      &._recruit {
        &::after {
          @include pc_tab {
            background: url(#{$img_dir}/p-recruit/mv-main-img.png);
          }
          @include sp {
            background: url(#{$img_dir}/p-recruit/sp/mv-main-img.png)no-repeat left top/cover;
          }
        }
      }
      &._colo {
        &::after {
          @include pc_tab {
            background: url(#{$img_dir}/p-colorectal/mv-main-img.png);
          }
          @include sp {
            background: url(#{$img_dir}/p-colorectal/sp/mv-main-img.png)no-repeat left top/cover;
          }
        }
      }
      &._cancer {
        &::after {
          @include pc_tab {
            background: url(#{$img_dir}/p-cancer/mv-main-img.png);
          }
          @include sp {
            background: url(#{$img_dir}/p-cancer/sp/mv-main-img.png)no-repeat left top/cover;
          }
        }
      }
      &._surgery {
        &::after {
          @include pc_tab {
            background: url(#{$img_dir}/p-surgery/mv-main-img.png);
          }
          @include sp {
            background: url(#{$img_dir}/p-surgery/sp/mv-main-img.png)no-repeat left top/cover;
          }
        }
      }
      &._check {
        &::after {
          @include pc_tab {
            background: url(#{$img_dir}/p-check-up/mv-main-img.png);
          }
          @include sp {
            background: url(#{$img_dir}/p-check-up/sp/mv-main-img.png)no-repeat left top/cover;
          }
        }
      }
      &._private {
        &::after {
          @include pc_tab {
            background: url(#{$img_dir}/p-private/mv-main-img.png);
          }
          @include sp {
            background: url(#{$img_dir}/p-private/sp/mv-main-img.png)no-repeat left top/cover;
          }
        }
      }
      &._breast {
        &::after {
          @include pc_tab {
            background: url(#{$img_dir}/p-breast/mv-main-img.png);
          }
          @include sp {
            background: url(#{$img_dir}/p-breast/sp/mv-main-img.png)no-repeat left top/cover;
          }
        }
      }
    }
  }

  .sec_ttl_mv {
    position: relative;
    z-index: 10;
    &::before {
      position: absolute;
      right: -80px;
      bottom: 0;
      width: 120px;
      height: 122px;
      background: url(#{$img_dir}/p-top/mv-res-icon.png);
      background-size: cover;
      content: "";

      @include sp {
        display: none;
      }
    }
    .stand {
      position: relative;
      &::before {
        position: absolute;
        bottom: 0;
        left: -5px;
        z-index: -1;
        width: 130px;
        height: 40px;
        background: url(#{$img_dir}/p-top/marker.svg);
        content: "";

        @include sp {
          left: 0;
          width: 78px;
          height: 24px;
          background: url(#{$img_dir}/p-top/sp/marker.svg);
        }
      }
    }
  }

  .mv_txt {
    position: relative;
    z-index: 5;
    margin: 15px 0 0;

    @include sp {
      margin: 10px 0 0;
    }
  }

  .left_img_list {
    position: relative;
    display: flex;
    width: 460px;
    border-radius: 10px;

    @include sp {
      width: 184px;
      border-radius: 6px;
    }
    @include pc_large {
      width: 520px;
    }
  }
  .left_img_item {
    width: fit-content;
    .img {
      @include sp {
        width: 184px;
        height: 231px;
      }
    }
  }
  .right_img {
    margin: 0 0 0 10px;

    @include sp {
      margin: 0 0 0 8px;
    }
  }
  .right_img_wrapper_01 {
    width: fit-content;

    @include sp {
    }
    .img {
      @include sp {
        width: 183px;
        height: 112px;
      }
    }
  }
  .right_img_wrapper_02 {
    display: flex;
    margin: 10px 0 0;

    @include sp {
      margin: 8px 0 0;
    }
    .right_img_item_01 {
      width: fit-content;

      @include sp {
      }
      .img {
        @include sp {
          width: 109px;
          height: 111px;
        }
      }
    }
    .right_img_item_02 {
      margin: 0 0 0 10px;
      width: fit-content;

      @include sp {
        margin: 0 0 0 8px;
      }
      .img {
        @include sp {
          width: 66px;
          height: 111px;
        }
      }
    }
  }
  .dots_02 {
    position: relative;
    z-index: 20;
    margin: -30px 0 0 345px;
    width: fit-content;

    @include sp {
      margin: -30px 0 0 112px;
    }
    @include pc_large {
      margin: -30px 0 0 405px;
    }
    .slick-dots {
      position: inherit;
      margin: 0 0 15px auto;
      width: fit-content;
      text-align: center;

      @include sp {
        margin: 2px 0 0;
      }
      li {
        display: inline-block;
      }
      button {
        /*ドットのデザイン*/
        display: block;
        margin: 0 8px 0 0;
        padding: 0;
        width: 12px;
        height: 12px;
        outline: none;
        border: 0;
        border-radius: 50%;
        background: $white;
        font-size: 0;
        cursor: pointer;

        @include sp {
          width: 6px;
          height: 6px;
        }
      }
      .slick-active button {
        /*ドットが選択された際の色変化*/
        background: $color_19;
      }
    }
  }
}

/* =================================
 l_content
================================= */
.l_content {
}

/* =================================
 l_main
================================= */
.l_main {
}

/* =================================
 l_side
================================= */
.l_side {
}

/* =================================
 l_footer
================================= */
.l_footer {
  margin: 55px 0 0;
  width: 100vw;
  background-color: $color_33;

  @include sp {
    margin: 40px auto 0;
    background: $color_9;
  }
  .footer_contents {
    display: flex;
    margin: 0 auto;
    width: 1120px;

    @include sp {
      flex-direction: column;
      padding: 0 0 90px;
      width: 100%;
    }
  }
  .box_01 {
    padding: 65px 0;

    @include sp {
      padding: 42px 15px 0;
      background: $color_33;
    }
    @include sp_small {
      margin: 0 auto;
      padding: 42px 0 0;
    }
  }
  .box_02 {
    margin: 0 0 0 70px;

    @include sp {
      margin: 0;
    }
    .menu_list_wrapper {
      display: flex;
      padding: 60px 0 0;

      @include sp {
        flex-direction: column;
        padding: 40px 22px 30px;
        background: $color_33;
      }
    }
  }
  .pic_company_logo {
    display: block;
    width: fit-content;
    transition: all .3s;

    @include sp {
      margin: 0 auto;
    }
    .img {
      @include sp {
        width: 248px;
        height: 32px;
      }
    }
    &:hover {
      @include pc_tab {
        opacity: .7;
        transition: all .3s;
      }
    }
  }
  .txt_01 {
    margin: 20px 0 0;
  }
  .small_box {
    margin: 20px auto 0;
    .img {
      @include sp {
        width: 345px;
        height: 119px;
      }
    }
  }

  .menu_list {
    width: 180px;

    @include sp {
      width: 330px;
    }
  }
  .menu_list + .menu_list {
    margin: 0 0 0 40px;

    @include sp {
      margin: 0;
    }
  }
  .menu_list_01 {
    .menu_item:last-of-type {
      @include sp {
        border-bottom: 1px dashed $color_11;
      }
    }
  }
  .menu_list_02 {
    @include sp {
    }
  }
  .menu_list_03 {
    @include sp {
    }
  }
  .menu_item {
    position: relative;
    border-bottom: 1px dashed $color_11;
    transition: all .3s;

    @include sp {
    }
    &:last-of-type {
      @include sp {
        border-bottom: none;
      }
    }
    .link_txt {
      display: flex;
      align-items: inherit;
      justify-content: space-between;
      padding: 10px 0;

      @include sp {
        align-items: center;
        padding: 14px 0;
      }
      &::after {
        margin: 9px 0 0;
        width: 7px;
        height: 11px;
        background-image: url(#{$img_dir}/p-top/footer_menu_icon.svg);
        background-position: center;
        background-repeat: no-repeat;
        content: "";

        @include sp {
          margin: 0;
        }
      }
    }
    &:hover {
      @include pc_tab {
        opacity: .7;
        transition: all .3s;
      }
    }
    &.menu_item_pt02 {
      border-bottom: none;
    }
    &.menu_item_small {
      padding: 0 0 0 20px;
      border-top: 1px dashed $color_32;
      border-bottom: none;
      &:last-of-type {
        border-bottom: 1px dashed $color_11;
      }
    }
  }
  .small {
    display: block;
    margin: 35px 0 0 auto;
    width: fit-content;

    @include sp {
      margin: 20px auto 0;
    }
  }
}
